<template>
  <div>
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2000 585"
      style="enable-background: new 0 0 2000 585"
      xml:space="preserve"
      width="100vw"
    >
        
        <g id="img">
            <image
            style="overflow: visible; enable-background: new"
            width="2000"
            height="585"
            xlink:href="/img/zero2/marisol-house-sketch.png"
            ></image>
        </g>
        <g class="tesla-batteries-content" >
            <g id="tesla-circle" v-bind:class="{'opac':over == 'tesla'}">
                <path class="style00" d="M132,376c-12.9,0-23.3-10.5-23.3-23.3s10.5-23.3,23.3-23.3s23.3,10.5,23.3,23.3S144.8,376,132,376z M132,334.4
                    c-10.1,0-18.3,8.2-18.3,18.3S121.9,371,132,371s18.3-8.2,18.3-18.3S142.1,334.4,132,334.4z"/>
            </g>

            <g id="tesla-content" v-bind:class="{'opac':over != 'tesla'}">
                <g id="Text_14_">
                    <path class="style00" d="M118.4,84.3h-0.6c0-0.3,0-0.6-0.1-0.8s-0.1-0.5-0.2-0.6c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.6-0.1-1-0.1
                        h-3.4v12.6c0,0.4,0.1,0.7,0.2,0.9c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.4,0.2,0.6,0.2s0.5,0,0.8,0v0.6c-0.5,0-1.1,0-1.5,0
                        c-0.5,0-1,0-1.5,0c-0.5,0-1.1,0-1.6,0c-0.5,0-1.1,0-1.7,0v-0.6c0.3,0,0.6,0,0.8,0s0.5-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.4-0.5
                        c0.1-0.2,0.2-0.5,0.2-0.9V82.3h-2.9c-0.5,0-0.9,0-1.2,0.1c-0.3,0.1-0.5,0.2-0.7,0.3c-0.2,0.2-0.3,0.4-0.4,0.6
                        c-0.1,0.3-0.2,0.6-0.3,0.9h-0.6l0.2-3.6h0.4c0.1,0.3,0.3,0.5,0.6,0.5c0.3,0.1,0.5,0.1,0.8,0.1h10.2c0.4,0,0.7-0.1,1-0.2
                        c0.3-0.1,0.4-0.3,0.5-0.5h0.5L118.4,84.3z"/>
                    <path class="style00" d="M119.6,90.9c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.9c0,0.5,0.1,1,0.3,1.5s0.4,0.9,0.7,1.2c0.3,0.4,0.6,0.6,1,0.8
                        c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.2-0.1,1.7-0.4c0.5-0.3,0.9-0.6,1.4-1.1l0.3,0.3c-0.3,0.3-0.5,0.7-0.8,1c-0.3,0.3-0.6,0.6-1,0.8
                        c-0.3,0.2-0.7,0.4-1.1,0.6c-0.4,0.1-0.8,0.2-1.3,0.2c-0.9,0-1.6-0.2-2.2-0.6c-0.6-0.4-1-0.8-1.3-1.3c-0.3-0.5-0.5-1.1-0.6-1.6
                        c-0.1-0.6-0.2-1-0.2-1.4c0-1.1,0.2-2,0.5-2.7c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.4,1-0.8,1.5-0.9c0.5-0.2,1-0.3,1.4-0.3
                        c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.9,0.4,1.2,0.7s0.7,0.7,0.9,1.1c0.2,0.4,0.3,1,0.3,1.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
                        l-0.2,0.1H119.6z M123.6,90.1c0.3,0,0.5-0.1,0.5-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.6-0.2-1.1-0.5-1.5s-0.8-0.6-1.4-0.6
                        c-0.7,0-1.3,0.2-1.7,0.7s-0.7,1.1-0.9,1.9H123.6z"/>
                    <path class="style00" d="M128.4,93.9c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.3,0.3,0.6,0.6,0.9c0.2,0.2,0.5,0.4,0.8,0.6
                        c0.3,0.1,0.7,0.2,1.1,0.2c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
                        c0-0.4-0.1-0.8-0.4-1.1c-0.2-0.3-0.5-0.5-0.9-0.7c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.3-0.7-0.6-0.9-1
                        c-0.2-0.4-0.4-0.9-0.4-1.5c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.6-0.9c0.3-0.3,0.6-0.5,1.1-0.7c0.4-0.2,1-0.3,1.6-0.3
                        c0.4,0,0.8,0.1,1.2,0.2c0.5,0.1,0.9,0.3,1.3,0.5l0.2,2.3h-0.5c-0.1-0.7-0.4-1.3-0.8-1.7c-0.4-0.4-1-0.6-1.7-0.6
                        c-0.4,0-0.9,0.1-1.2,0.4c-0.4,0.3-0.5,0.6-0.5,1.1c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.5,1,0.7c0.4,0.2,0.8,0.4,1.3,0.6
                        s0.9,0.4,1.3,0.6c0.4,0.2,0.7,0.6,1,0.9c0.3,0.4,0.4,0.9,0.4,1.4c0,0.4-0.1,0.7-0.2,1.1s-0.4,0.7-0.7,1s-0.7,0.5-1.2,0.7
                        c-0.5,0.2-1.1,0.3-1.7,0.3c-0.4,0-0.9-0.1-1.4-0.2s-0.9-0.3-1.3-0.6L128,94L128.4,93.9z"/>
                    <path class="style00" d="M136.5,96.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7V83.2c0-0.4,0-0.6-0.1-0.9
                        c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.2v-0.4c0.4-0.1,0.9-0.3,1.3-0.5s0.8-0.3,1-0.5
                        c0.1-0.1,0.2-0.1,0.3-0.2s0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v15.5c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6
                        c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0V96.5z"/>
                    <path class="style00" d="M152.5,96.2c-0.3,0.3-0.7,0.6-1,0.8s-0.8,0.4-1.2,0.4c-0.5,0-0.8-0.2-1-0.5c-0.2-0.4-0.3-0.7-0.4-1.1
                        c-0.5,0.5-1.1,0.9-1.6,1.2s-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.2-1.8-0.6s-0.7-0.9-0.7-1.5c0-0.4,0.1-0.8,0.3-1.1
                        c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.3,1-0.5,1.8-0.8c0.8-0.3,1.7-0.6,3-1v-1.5c0-1.3-0.7-1.9-2.2-1.9c-0.5,0-0.9,0.1-1.2,0.4
                        c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3-0.1,0.6-0.4,0.7c-0.3,0.2-0.6,0.2-0.8,0.2c-0.2,0-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
                        c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.7-0.4,1.1-0.6c0.2-0.1,0.4-0.2,0.5-0.3
                        c0.2-0.1,0.3-0.2,0.5-0.2c0.1-0.1,0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.2,0.9,0.4
                        c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.5,0.1,0.8,0.2,1c0.1,0.2,0.3,0.3,0.6,0.3c0.1,0,0.3,0,0.4-0.1
                        c0.1-0.1,0.3-0.2,0.4-0.3L152.5,96.2z M148.8,92.1c-0.3,0.1-0.7,0.2-1.1,0.4c-0.5,0.1-0.9,0.3-1.3,0.5c-0.4,0.2-0.8,0.4-1.1,0.7
                        c-0.3,0.3-0.5,0.6-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.5,1.1,0.5c0.5,0,1-0.1,1.3-0.4c0.4-0.3,0.7-0.6,1.1-0.9V92.1z"/>
                    <path class="style00" d="M163.5,88.4c0.2-0.2,0.5-0.4,0.7-0.6c0.3-0.2,0.5-0.4,0.8-0.5c0.3-0.2,0.6-0.3,0.9-0.4
                        c0.3-0.1,0.6-0.1,0.9-0.1c0.6,0,1.2,0.1,1.7,0.4c0.5,0.3,1,0.6,1.3,1s0.6,0.9,0.8,1.5c0.2,0.6,0.3,1.2,0.3,1.8
                        c0,0.7-0.1,1.5-0.4,2.2c-0.3,0.7-0.6,1.3-1.1,1.9c-0.5,0.6-1,1-1.7,1.3s-1.4,0.5-2.2,0.5c-0.4,0-0.8,0-1.1-0.1
                        c-0.3-0.1-0.6-0.1-0.9-0.2s-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.2-0.5-0.2l-0.7,0.5l-0.5-0.2c0.1-0.7,0.2-1.3,0.2-2
                        c0.1-0.7,0.1-1.3,0.1-2v-9.7c0-0.4,0-0.6-0.1-0.9c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.2v-0.4
                        c0.4-0.1,0.9-0.3,1.3-0.5s0.8-0.3,1-0.5c0.1-0.1,0.2-0.1,0.3-0.2s0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3V88.4z M163.5,94.5
                        c0,0.2,0,0.4,0.1,0.7c0.1,0.3,0.2,0.5,0.4,0.8c0.2,0.2,0.4,0.4,0.7,0.6s0.6,0.2,1,0.2c0.6,0,1.1-0.1,1.5-0.4s0.8-0.6,1.1-1
                        c0.3-0.4,0.5-0.9,0.6-1.4c0.1-0.5,0.2-1,0.2-1.5c0-0.6-0.1-1.2-0.3-1.7c-0.2-0.5-0.5-1-0.8-1.4s-0.7-0.7-1.2-1
                        c-0.4-0.2-0.9-0.4-1.3-0.4c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.7,0.5-1,0.7V94.5z"/>
                    <path class="style00" d="M182.4,96.2c-0.3,0.3-0.7,0.6-1,0.8s-0.8,0.4-1.2,0.4c-0.5,0-0.8-0.2-1-0.5c-0.2-0.4-0.3-0.7-0.4-1.1
                        c-0.5,0.5-1.1,0.9-1.6,1.2s-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.2-1.8-0.6s-0.7-0.9-0.7-1.5c0-0.4,0.1-0.8,0.3-1.1
                        c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.3,1-0.5,1.8-0.8c0.8-0.3,1.7-0.6,3-1v-1.5c0-1.3-0.7-1.9-2.2-1.9c-0.5,0-0.9,0.1-1.2,0.4
                        c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3-0.1,0.6-0.4,0.7c-0.3,0.2-0.6,0.2-0.8,0.2c-0.2,0-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
                        c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.7-0.4,1.1-0.6c0.2-0.1,0.4-0.2,0.5-0.3
                        c0.2-0.1,0.3-0.2,0.5-0.2c0.1-0.1,0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.2,0.9,0.4
                        c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.5,0.1,0.8,0.2,1c0.1,0.2,0.3,0.3,0.6,0.3c0.1,0,0.3,0,0.4-0.1
                        c0.1-0.1,0.3-0.2,0.4-0.3L182.4,96.2z M178.8,92.1c-0.3,0.1-0.7,0.2-1.1,0.4c-0.5,0.1-0.9,0.3-1.3,0.5c-0.4,0.2-0.8,0.4-1.1,0.7
                        c-0.3,0.3-0.5,0.6-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.5,1.1,0.5c0.5,0,1-0.1,1.3-0.4c0.4-0.3,0.7-0.6,1.1-0.9V92.1z"/>
                    <path class="style00" d="M186.9,94.3c0,0.4,0,0.7,0.1,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.1,0.5,0.1
                        c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.5-0.3,0.8-0.5l0.3,0.3c-0.4,0.4-0.8,0.8-1.3,1.1s-1,0.5-1.7,0.5c-0.7,0-1.3-0.2-1.6-0.5
                        c-0.4-0.4-0.5-0.9-0.5-1.5v-7h-1.5V88c0.3-0.2,0.6-0.4,0.8-0.6c0.2-0.2,0.5-0.4,0.7-0.6c0.2-0.2,0.5-0.5,0.7-0.8
                        c0.2-0.3,0.5-0.7,0.8-1.1h0.3v2.3h3.3v1.2h-3.3V94.3z"/>
                    <path class="style00" d="M194.4,94.3c0,0.4,0,0.7,0.1,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.1,0.5,0.1
                        c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.5-0.3,0.8-0.5l0.3,0.3c-0.4,0.4-0.8,0.8-1.3,1.1s-1,0.5-1.7,0.5c-0.7,0-1.3-0.2-1.6-0.5
                        c-0.4-0.4-0.5-0.9-0.5-1.5v-7h-1.5V88c0.3-0.2,0.6-0.4,0.8-0.6c0.2-0.2,0.5-0.4,0.7-0.6c0.2-0.2,0.5-0.5,0.7-0.8
                        c0.2-0.3,0.5-0.7,0.8-1.1h0.3v2.3h3.3v1.2h-3.3V94.3z"/>
                    <path class="style00" d="M200.7,90.9c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.9c0,0.5,0.1,1,0.3,1.5s0.4,0.9,0.7,1.2c0.3,0.4,0.6,0.6,1,0.8
                        c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.2-0.1,1.7-0.4c0.5-0.3,0.9-0.6,1.4-1.1l0.3,0.3c-0.3,0.3-0.5,0.7-0.8,1c-0.3,0.3-0.6,0.6-1,0.8
                        c-0.3,0.2-0.7,0.4-1.1,0.6c-0.4,0.1-0.8,0.2-1.3,0.2c-0.9,0-1.6-0.2-2.2-0.6c-0.6-0.4-1-0.8-1.3-1.3c-0.3-0.5-0.5-1.1-0.6-1.6
                        c-0.1-0.6-0.2-1-0.2-1.4c0-1.1,0.2-2,0.5-2.7c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.4,1-0.8,1.5-0.9c0.5-0.2,1-0.3,1.4-0.3
                        c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.9,0.4,1.2,0.7s0.7,0.7,0.9,1.1c0.2,0.4,0.3,1,0.3,1.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
                        l-0.2,0.1H200.7z M204.7,90.1c0.3,0,0.5-0.1,0.5-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.6-0.2-1.1-0.5-1.5s-0.8-0.6-1.4-0.6
                        c-0.7,0-1.3,0.2-1.7,0.7s-0.7,1.1-0.9,1.9H204.7z"/>
                    <path class="style00" d="M208.7,96.5h0.6c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.3c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3
                        c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1v-0.3c0.8-0.3,1.5-0.6,1.9-0.9c0.4-0.3,0.7-0.4,0.8-0.4c0.1,0,0.2,0.1,0.2,0.3v2
                        h0c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.6-0.2,1-0.2c0.3,0,0.6,0.1,0.9,0.3
                        c0.3,0.2,0.4,0.5,0.4,0.9c0,0.3-0.1,0.6-0.3,0.8s-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
                        c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.3s-0.3,0.3-0.5,0.6
                        c-0.1,0.2-0.2,0.5-0.2,0.9v5.3c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.3v0.6c-0.6,0-1.2,0-1.7,0c-0.5,0-1,0-1.5,0
                        c-0.5,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.3,0V96.5z"/>
                    <path class="style00" d="M217.6,96.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.1c0-0.2,0-0.4-0.1-0.6s-0.1-0.3-0.2-0.4
                        s-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3v-0.4c0.3-0.1,0.5-0.2,0.8-0.2s0.5-0.2,0.8-0.2c0.2-0.1,0.4-0.1,0.6-0.2
                        c0.1-0.1,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.3v8.6c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4
                        v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0V96.5z M221.1,82.2c0,0.3-0.1,0.6-0.3,0.8
                        c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
                        c0.3,0,0.6,0.1,0.8,0.3C221,81.7,221.1,81.9,221.1,82.2z"/>
                    <path class="style00" d="M225.7,90.9c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.9c0,0.5,0.1,1,0.3,1.5s0.4,0.9,0.7,1.2c0.3,0.4,0.6,0.6,1,0.8
                        c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.2-0.1,1.7-0.4c0.5-0.3,0.9-0.6,1.4-1.1l0.3,0.3c-0.3,0.3-0.5,0.7-0.8,1c-0.3,0.3-0.6,0.6-1,0.8
                        c-0.3,0.2-0.7,0.4-1.1,0.6c-0.4,0.1-0.8,0.2-1.3,0.2c-0.9,0-1.6-0.2-2.2-0.6c-0.6-0.4-1-0.8-1.3-1.3c-0.3-0.5-0.5-1.1-0.6-1.6
                        c-0.1-0.6-0.2-1-0.2-1.4c0-1.1,0.2-2,0.5-2.7c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.4,1-0.8,1.5-0.9c0.5-0.2,1-0.3,1.4-0.3
                        c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.9,0.4,1.2,0.7s0.7,0.7,0.9,1.1c0.2,0.4,0.3,1,0.3,1.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
                        l-0.2,0.1H225.7z M229.7,90.1c0.3,0,0.5-0.1,0.5-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.6-0.2-1.1-0.5-1.5s-0.8-0.6-1.4-0.6
                        c-0.7,0-1.3,0.2-1.7,0.7s-0.7,1.1-0.9,1.9H229.7z"/>
                    <path class="style00" d="M234.4,93.9c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.3,0.3,0.6,0.6,0.9c0.2,0.2,0.5,0.4,0.8,0.6
                        c0.3,0.1,0.7,0.2,1.1,0.2c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
                        c0-0.4-0.1-0.8-0.4-1.1c-0.2-0.3-0.5-0.5-0.9-0.7c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.3-0.7-0.6-0.9-1
                        c-0.2-0.4-0.4-0.9-0.4-1.5c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.6-0.9c0.3-0.3,0.6-0.5,1.1-0.7c0.4-0.2,1-0.3,1.6-0.3
                        c0.4,0,0.8,0.1,1.2,0.2c0.5,0.1,0.9,0.3,1.3,0.5l0.2,2.3h-0.5c-0.1-0.7-0.4-1.3-0.8-1.7c-0.4-0.4-1-0.6-1.7-0.6
                        c-0.4,0-0.9,0.1-1.2,0.4c-0.4,0.3-0.5,0.6-0.5,1.1c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.5,1,0.7c0.4,0.2,0.8,0.4,1.3,0.6
                        s0.9,0.4,1.3,0.6c0.4,0.2,0.7,0.6,1,0.9c0.3,0.4,0.4,0.9,0.4,1.4c0,0.4-0.1,0.7-0.2,1.1s-0.4,0.7-0.7,1s-0.7,0.5-1.2,0.7
                        c-0.5,0.2-1.1,0.3-1.7,0.3c-0.4,0-0.9-0.1-1.4-0.2s-0.9-0.3-1.3-0.6L234,94L234.4,93.9z"/>
                </g>
                <path id="Arrow_14_" class="style00" d="M103.2,330.6c-0.7-3.7-1.1-7.5-1.1-11.2c0-1.3,0.1-2.7,0.1-4c0-1.1-2.8-0.5-2.9,0.4
                    c-0.1,1.4-0.1,2.8-0.1,4.3c-0.1,1.7-0.1,3.5-0.2,5.2c-4.5-6.7-8.2-13.9-10.8-21.5c-1.6-4.5-2.8-9.1-3.5-13.7
                    c-0.8-5-1.1-10.1-1.5-15.2c-1.5-18.4-2.3-36.9-2.7-55.3c-0.3-18.2-0.2-36.5-0.3-54.7c0-7.4-0.2-14.9,0.2-22.3
                    c0.4-6.5,0.9-13,1.4-19.5c0.5-7.1,1-14.1,1.4-21.2c0.2-5.5,0.5-11.1-0.1-16.6c-0.1-0.9-0.2-1.8-0.4-2.7c-0.2-0.9-3.1-0.2-2.9,0.8
                    c1,4.8,0.7,9.9,0.6,14.8c-0.2,6.9-0.7,13.8-1.2,20.7c-0.5,6.8-1,13.6-1.5,20.4c-0.4,6.4-0.5,12.7-0.5,19.1c0,18.7-0.1,37.3,0.2,56
                    c0.3,18.2,1,36.4,2.3,54.6c0.7,9.9,1.1,20,3.7,29.7c2.4,9.1,6.4,17.8,11.5,25.7c0.3,0.5,0.7,1,1,1.6c-3.2-1.3-6.8-2-10.3-1.8
                    c-0.6,0-1.6,0.2-1.9,0.8c-0.3,0.6,0.2,0.8,0.8,0.8c5.9-0.3,11.7,1.9,16,6C100.9,332.1,103.4,331.6,103.2,330.6z"/>
            </g>


        </g>
        <g class="aluminium-content">
            <g id="aluminium-circle" v-bind:class="{'opac':over == 'aluminium'}">
                <path class="style00" d="M499.6,307.1c-12.9,0-23.3-10.5-23.3-23.3s10.5-23.3,23.3-23.3c12.9,0,23.3,10.5,23.3,23.3
                    S512.4,307.1,499.6,307.1z M499.6,265.5c-10.1,0-18.3,8.2-18.3,18.3c0,10.1,8.2,18.3,18.3,18.3s18.3-8.2,18.3-18.3
                    C517.9,273.7,509.7,265.5,499.6,265.5z"/>
            </g>

            
            <g id="aluminium-content" v-bind:class="{'opac':over != 'aluminium'}">
                <g id="Text_20_">
                    <path class="style00" d="M508.5,96.9c1.1-0.3,2.1-0.7,3-1.2c0.9-0.6,1.8-1.2,2.5-2s1.3-1.7,1.8-2.7c0.4-1,0.7-2,0.9-3.2l0,0
                        c-0.2,0.3-0.4,0.7-0.7,0.9s-0.5,0.5-0.8,0.7c-0.3,0.2-0.6,0.4-1,0.5c-0.4,0.1-0.8,0.2-1.2,0.2c-0.7,0-1.2-0.1-1.7-0.4
                        s-0.9-0.6-1.2-1c-0.3-0.4-0.5-0.8-0.7-1.3c-0.1-0.5-0.2-0.9-0.2-1.3c0-0.5,0.1-1,0.3-1.6c0.2-0.6,0.5-1.1,0.9-1.6
                        c0.4-0.5,0.9-0.9,1.5-1.2s1.4-0.5,2.2-0.5c0.8,0,1.5,0.2,2.1,0.5c0.6,0.3,1.1,0.8,1.5,1.3c0.4,0.5,0.7,1.2,0.9,1.8
                        c0.2,0.7,0.3,1.4,0.3,2.1c0,1.2-0.2,2.2-0.6,3.2c-0.4,1-0.9,1.8-1.5,2.6c-0.6,0.8-1.3,1.4-2,2c-0.8,0.6-1.5,1.1-2.2,1.4
                        c-0.7,0.4-1.4,0.7-2.1,0.9s-1.2,0.3-1.5,0.3L508.5,96.9z M511,85.3c0,0.4,0.1,0.9,0.2,1.3s0.3,0.8,0.5,1.1s0.5,0.6,0.9,0.8
                        c0.4,0.2,0.8,0.3,1.2,0.3c0.4,0,0.7-0.1,1-0.2s0.6-0.3,0.9-0.5c0.3-0.2,0.5-0.5,0.6-0.8c0.2-0.3,0.2-0.6,0.2-1
                        c0-0.5,0-1.1-0.1-1.6s-0.2-1-0.5-1.4c-0.2-0.4-0.5-0.7-0.9-1c-0.4-0.3-0.8-0.4-1.4-0.4c-0.5,0-0.9,0.1-1.3,0.3
                        c-0.3,0.2-0.6,0.4-0.9,0.8s-0.4,0.7-0.5,1.1C511,84.5,511,84.9,511,85.3z"/>
                    <path class="style00" d="M521,96.9c1.1-0.3,2.1-0.7,3-1.2s1.8-1.2,2.5-2c0.7-0.8,1.3-1.7,1.8-2.7c0.4-1,0.7-2,0.9-3.2l0,0
                        c-0.2,0.3-0.4,0.7-0.7,0.9s-0.5,0.5-0.8,0.7c-0.3,0.2-0.6,0.4-1,0.5c-0.4,0.1-0.8,0.2-1.2,0.2c-0.7,0-1.2-0.1-1.7-0.4
                        s-0.9-0.6-1.2-1s-0.5-0.8-0.7-1.3c-0.1-0.5-0.2-0.9-0.2-1.3c0-0.5,0.1-1,0.3-1.6c0.2-0.6,0.5-1.1,0.9-1.6s0.9-0.9,1.5-1.2
                        c0.6-0.3,1.4-0.5,2.2-0.5c0.8,0,1.5,0.2,2.1,0.5s1.1,0.8,1.5,1.3c0.4,0.5,0.7,1.2,0.9,1.8c0.2,0.7,0.3,1.4,0.3,2.1
                        c0,1.2-0.2,2.2-0.6,3.2s-0.9,1.8-1.5,2.6c-0.6,0.8-1.3,1.4-2,2c-0.8,0.6-1.5,1.1-2.2,1.4c-0.7,0.4-1.4,0.7-2.1,0.9
                        c-0.6,0.2-1.2,0.3-1.5,0.3L521,96.9z M523.5,85.3c0,0.4,0.1,0.9,0.2,1.3s0.3,0.8,0.5,1.1c0.2,0.3,0.5,0.6,0.9,0.8
                        c0.4,0.2,0.8,0.3,1.2,0.3c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.6-0.3,0.9-0.5c0.3-0.2,0.5-0.5,0.6-0.8c0.2-0.3,0.2-0.6,0.2-1
                        c0-0.5,0-1.1-0.1-1.6s-0.2-1-0.5-1.4c-0.2-0.4-0.5-0.7-0.9-1c-0.4-0.3-0.8-0.4-1.4-0.4c-0.5,0-0.9,0.1-1.3,0.3
                        c-0.3,0.2-0.6,0.4-0.9,0.8s-0.4,0.7-0.5,1.1C523.5,84.5,523.5,84.9,523.5,85.3z"/>
                    <path class="style00" d="M542.2,84.8c0,0.6-0.1,1.1-0.3,1.5s-0.5,0.9-0.8,1.2c-0.3,0.4-0.8,0.6-1.2,0.8c-0.5,0.2-1,0.3-1.5,0.3
                        c-0.5,0-1-0.1-1.4-0.3c-0.4-0.2-0.8-0.4-1.1-0.8c-0.3-0.3-0.6-0.7-0.7-1.1c-0.2-0.4-0.3-0.9-0.3-1.4c0-0.6,0.1-1.1,0.3-1.5
                        s0.5-0.9,0.8-1.2c0.3-0.4,0.8-0.6,1.2-0.8c0.5-0.2,1-0.3,1.5-0.3c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.8,0.4,1.1,0.8
                        c0.3,0.3,0.6,0.7,0.7,1.1C542.1,83.9,542.2,84.3,542.2,84.8z M540.4,84.7c0-0.3,0-0.6-0.1-0.9c0-0.3-0.1-0.7-0.3-1
                        c-0.1-0.3-0.3-0.5-0.6-0.7c-0.3-0.2-0.6-0.3-1-0.3c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.5-0.6,0.9c-0.1,0.3-0.2,0.7-0.3,1.1
                        c0,0.4-0.1,0.7-0.1,1.1c0,0.3,0,0.6,0.1,1c0,0.3,0.1,0.7,0.3,1c0.1,0.3,0.3,0.5,0.6,0.7c0.3,0.2,0.6,0.3,1,0.3
                        c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.5-0.5,0.6-0.9c0.1-0.3,0.2-0.7,0.3-1.1C540.4,85.4,540.4,85.1,540.4,84.7z M537.9,97.6L548.1,81h1
                        L539,97.6H537.9z M552.7,93.5c0,0.6-0.1,1.1-0.3,1.5s-0.5,0.9-0.8,1.2c-0.3,0.4-0.8,0.6-1.2,0.8s-1,0.3-1.5,0.3
                        c-0.5,0-1-0.1-1.4-0.3s-0.8-0.4-1.1-0.8c-0.3-0.3-0.6-0.7-0.7-1.1c-0.2-0.4-0.3-0.9-0.3-1.4c0-0.6,0.1-1.1,0.3-1.5
                        s0.5-0.9,0.8-1.2c0.3-0.4,0.8-0.6,1.2-0.8c0.5-0.2,1-0.3,1.5-0.3c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.8,0.4,1.1,0.8
                        c0.3,0.3,0.6,0.7,0.7,1.1C552.6,92.5,552.7,93,552.7,93.5z M550.9,93.4c0-0.3,0-0.6-0.1-0.9c0-0.3-0.1-0.7-0.3-1
                        c-0.1-0.3-0.3-0.5-0.6-0.7c-0.3-0.2-0.6-0.3-1-0.3c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.5-0.6,0.9s-0.2,0.7-0.3,1.1
                        c0,0.4-0.1,0.7-0.1,1.1c0,0.3,0,0.6,0.1,1s0.1,0.7,0.3,1c0.1,0.3,0.3,0.5,0.6,0.7c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.3
                        c0.3-0.2,0.5-0.5,0.6-0.9s0.2-0.7,0.3-1.1C550.9,94.1,550.9,93.8,550.9,93.4z"/>
                    <path class="style00" d="M561.7,96.5h0.6c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.3c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3
                        c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1v-0.3c0.8-0.3,1.5-0.6,1.9-0.9c0.4-0.3,0.7-0.4,0.8-0.4c0.1,0,0.2,0.1,0.2,0.3v2
                        h0c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.6-0.2,1-0.2c0.3,0,0.6,0.1,0.9,0.3
                        c0.3,0.2,0.4,0.5,0.4,0.9c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
                        c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.3s-0.3,0.3-0.5,0.6
                        c-0.1,0.2-0.2,0.5-0.2,0.9v5.3c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.3v0.6c-0.6,0-1.2,0-1.7,0c-0.5,0-1,0-1.5,0
                        c-0.5,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.3,0V96.5z"/>
                    <path class="style00" d="M572.5,90.9c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.9c0,0.5,0.1,1,0.3,1.5s0.4,0.9,0.7,1.2c0.3,0.4,0.6,0.6,1,0.8
                        c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.2-0.1,1.7-0.4s0.9-0.6,1.4-1.1l0.3,0.3c-0.3,0.3-0.5,0.7-0.8,1c-0.3,0.3-0.6,0.6-1,0.8
                        c-0.3,0.2-0.7,0.4-1.1,0.6c-0.4,0.1-0.8,0.2-1.3,0.2c-0.9,0-1.6-0.2-2.2-0.6c-0.6-0.4-1-0.8-1.3-1.3c-0.3-0.5-0.5-1.1-0.6-1.6
                        c-0.1-0.6-0.2-1-0.2-1.4c0-1.1,0.2-2,0.5-2.7c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.4,1-0.8,1.5-0.9c0.5-0.2,1-0.3,1.4-0.3
                        c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.9,0.4,1.2,0.7s0.7,0.7,0.9,1.1c0.2,0.4,0.3,1,0.3,1.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
                        l-0.2,0.1H572.5z M576.4,90.1c0.3,0,0.5-0.1,0.5-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.6-0.2-1.1-0.5-1.5s-0.8-0.6-1.4-0.6
                        c-0.7,0-1.3,0.2-1.7,0.7s-0.7,1.1-0.9,1.9H576.4z"/>
                    <path class="style00" d="M589,88.3c0,0.3-0.1,0.5-0.2,0.7s-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3c-0.1-0.2-0.3-0.4-0.5-0.6
                        c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.2-0.7-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.3,0.4c-0.4,0.2-0.7,0.6-0.9,0.9s-0.4,0.8-0.6,1.3
                        s-0.2,0.9-0.2,1.3c0,0.6,0.1,1.1,0.3,1.7c0.2,0.5,0.4,1,0.8,1.4c0.3,0.4,0.7,0.7,1.2,1c0.5,0.2,1,0.4,1.7,0.4
                        c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.4,1.3-0.8l0.4,0.4c-0.6,0.6-1.2,1.2-1.9,1.7c-0.7,0.5-1.5,0.7-2.4,0.7
                        c-0.6,0-1.1-0.1-1.7-0.4s-1-0.6-1.4-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.6-0.4-1.1-0.4-1.7c0-0.8,0.1-1.6,0.4-2.3
                        c0.3-0.7,0.7-1.4,1.2-1.9s1.1-1,1.8-1.3s1.4-0.5,2.2-0.5c0.3,0,0.6,0,1,0.1c0.4,0.1,0.7,0.1,1,0.3c0.3,0.1,0.6,0.3,0.8,0.5
                        C588.9,87.8,589,88,589,88.3z"/>
                    <path class="style00" d="M590.2,87.1c0.4,0,0.9,0,1.3,0c0.4,0,0.9,0,1.3,0c0.3,0,0.7,0,1,0c0.3,0,0.7,0,1,0v0.6c-0.1,0-0.2,0-0.4,0
                        c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.6c0.1,0.3,0.2,0.6,0.4,1
                        c0.2,0.4,0.3,0.8,0.5,1.3c0.2,0.4,0.4,0.9,0.5,1.3c0.2,0.4,0.3,0.8,0.4,1.1c0.1,0.3,0.2,0.6,0.3,0.7h0c0.1-0.2,0.2-0.5,0.3-0.8
                        c0.1-0.3,0.3-0.7,0.5-1.1s0.3-0.8,0.5-1.2c0.2-0.4,0.3-0.8,0.5-1.1c0.1-0.4,0.2-0.7,0.3-0.9s0.1-0.4,0.1-0.6c0-0.2,0-0.4-0.1-0.6
                        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0v-0.6c0.3,0,0.7,0,1.1,0c0.4,0,0.7,0,1.1,0
                        c0.3,0,0.6,0,0.8,0c0.3,0,0.6,0,0.8,0v0.6c-0.5,0-0.9,0.1-1.1,0.4c-0.2,0.3-0.4,0.6-0.6,1l-5.1,11.9c-0.2,0.4-0.4,0.7-0.6,0.9
                        c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.4,0.8-0.4
                        c0.2,0,0.4,0,0.5,0.1c0.1,0,0.3,0.1,0.4,0.3c0.6-1.2,1.1-2.4,1.6-3.6c-0.3-0.8-0.6-1.4-0.8-2c-0.2-0.6-0.5-1.2-0.7-1.9
                        c-0.3-0.7-0.5-1.3-0.8-1.9c-0.2-0.6-0.4-1.1-0.6-1.5s-0.3-0.7-0.3-0.8c-0.2-0.3-0.4-0.5-0.6-0.7c-0.2-0.1-0.5-0.2-0.9-0.2V87.1z"
                        />
                    <path class="style00" d="M610.2,88.3c0,0.3-0.1,0.5-0.2,0.7s-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3c-0.1-0.2-0.3-0.4-0.5-0.6
                        c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.2-0.7-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.3,0.4c-0.4,0.2-0.7,0.6-0.9,0.9s-0.4,0.8-0.6,1.3
                        s-0.2,0.9-0.2,1.3c0,0.6,0.1,1.1,0.3,1.7c0.2,0.5,0.4,1,0.8,1.4c0.3,0.4,0.7,0.7,1.2,1c0.5,0.2,1,0.4,1.7,0.4
                        c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.4,1.3-0.8l0.4,0.4c-0.6,0.6-1.2,1.2-1.9,1.7c-0.7,0.5-1.5,0.7-2.4,0.7
                        c-0.6,0-1.1-0.1-1.7-0.4s-1-0.6-1.4-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.6-0.4-1.1-0.4-1.7c0-0.8,0.1-1.6,0.4-2.3
                        c0.3-0.7,0.7-1.4,1.2-1.9s1.1-1,1.8-1.3s1.4-0.5,2.2-0.5c0.3,0,0.6,0,1,0.1c0.4,0.1,0.7,0.1,1,0.3c0.3,0.1,0.6,0.3,0.8,0.5
                        C610.1,87.8,610.2,88,610.2,88.3z"/>
                    <path class="style00" d="M611.8,96.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7V83.2c0-0.4,0-0.6-0.1-0.9
                        c0-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2v-0.4c0.4-0.1,0.9-0.3,1.3-0.5s0.8-0.3,1-0.5
                        c0.1-0.1,0.2-0.1,0.3-0.2s0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v15.5c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6
                        c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0V96.5z"/>
                    <path class="style00" d="M619.9,90.9c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.9c0,0.5,0.1,1,0.3,1.5s0.4,0.9,0.7,1.2c0.3,0.4,0.6,0.6,1,0.8
                        c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.2-0.1,1.7-0.4s0.9-0.6,1.4-1.1l0.3,0.3c-0.3,0.3-0.5,0.7-0.8,1c-0.3,0.3-0.6,0.6-1,0.8
                        c-0.3,0.2-0.7,0.4-1.1,0.6c-0.4,0.1-0.8,0.2-1.3,0.2c-0.9,0-1.6-0.2-2.2-0.6c-0.6-0.4-1-0.8-1.3-1.3c-0.3-0.5-0.5-1.1-0.6-1.6
                        c-0.1-0.6-0.2-1-0.2-1.4c0-1.1,0.2-2,0.5-2.7c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.4,1-0.8,1.5-0.9c0.5-0.2,1-0.3,1.4-0.3
                        c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.9,0.4,1.2,0.7s0.7,0.7,0.9,1.1c0.2,0.4,0.3,1,0.3,1.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
                        l-0.2,0.1H619.9z M623.8,90.1c0.3,0,0.5-0.1,0.5-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.6-0.2-1.1-0.5-1.5s-0.8-0.6-1.4-0.6
                        c-0.7,0-1.3,0.2-1.7,0.7s-0.7,1.1-0.9,1.9H623.8z"/>
                    <path class="style00" d="M637.7,95.1c0,0.2,0,0.4,0,0.5c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.5,0h0.4v0.4h0
                        c-0.2,0-0.4,0.1-0.7,0.2c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.4,0.1-0.5,0.1c-0.1,0-0.2-0.1-0.2-0.2
                        s0-0.3,0-0.4v-1h0c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.1-1.8-0.4s-1-0.6-1.3-1.1
                        c-0.4-0.5-0.6-1-0.8-1.6s-0.3-1.2-0.3-1.8c0-0.8,0.1-1.5,0.4-2.2c0.3-0.7,0.7-1.3,1.2-1.8s1.1-0.9,1.8-1.3s1.4-0.5,2.2-0.5
                        c0.7,0,1.5,0.1,2.1,0.4v-4c0-0.4,0-0.6-0.1-0.9c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.2v-0.4
                        c0.4-0.1,0.9-0.3,1.3-0.5s0.8-0.3,1-0.5c0.1-0.1,0.2-0.1,0.3-0.2s0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3V95.1z M635.9,89.6
                        c0-0.4-0.1-0.8-0.2-1c-0.2-0.3-0.4-0.5-0.6-0.6c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3-0.1-0.5-0.1-0.8-0.1c-0.4,0-0.8,0.1-1.2,0.2
                        s-0.8,0.4-1.1,0.7s-0.6,0.8-0.8,1.3c-0.2,0.5-0.3,1.2-0.3,1.9c0,0.6,0.1,1.2,0.3,1.7c0.2,0.5,0.4,1,0.7,1.4s0.7,0.7,1.1,0.9
                        c0.4,0.2,0.9,0.3,1.4,0.3c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.5-0.1,0.7-0.3c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.2,0.2-0.4,0.2-0.6V89.6z"/>
                    <path class="style00" d="M517.9,122.2c-0.3,0.3-0.7,0.6-1,0.8s-0.8,0.4-1.2,0.4c-0.5,0-0.8-0.2-1-0.5c-0.2-0.4-0.3-0.7-0.4-1.1
                        c-0.5,0.5-1.1,0.9-1.6,1.2s-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.2-1.8-0.6s-0.7-0.9-0.7-1.5c0-0.4,0.1-0.8,0.3-1.1s0.5-0.6,0.9-0.8
                        c0.4-0.3,1-0.5,1.8-0.8c0.8-0.3,1.7-0.6,3-1v-1.5c0-1.3-0.7-1.9-2.2-1.9c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.5,0.7-0.5,1.2
                        c0,0.3-0.1,0.6-0.4,0.7s-0.6,0.2-0.8,0.2c-0.2,0-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6
                        c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.7-0.4,1.1-0.6c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.3-0.2,0.5-0.2s0.3-0.1,0.5-0.2
                        c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.2,0.9,0.4s0.5,0.4,0.7,0.6c0.2,0.2,0.3,0.5,0.3,0.8v6.2
                        c0,0.5,0.1,0.8,0.2,1c0.1,0.2,0.3,0.3,0.6,0.3c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.2,0.4-0.3L517.9,122.2z M514.3,118.1
                        c-0.3,0.1-0.7,0.2-1.1,0.4c-0.5,0.1-0.9,0.3-1.3,0.5c-0.4,0.2-0.8,0.4-1.1,0.7c-0.3,0.3-0.5,0.6-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1
                        c0.3,0.3,0.7,0.5,1.1,0.5c0.5,0,1-0.1,1.3-0.4s0.7-0.6,1.1-0.9V118.1z"/>
                    <path class="style00" d="M519.4,122.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-12.5c0-0.4,0-0.6-0.1-0.9
                        c0-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2v-0.4c0.4-0.1,0.9-0.3,1.3-0.5s0.8-0.3,1-0.5
                        c0.1-0.1,0.2-0.1,0.3-0.2s0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v15.5c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6
                        c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0V122.5z"/>
                    <path class="style00" d="M535.3,121.8c0,0.2,0.1,0.3,0.2,0.4c0.1,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0v0.5
                        c-0.2,0-0.5,0.1-0.9,0.1c-0.3,0.1-0.7,0.1-1,0.2s-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.1-0.6,0.1l-0.1-0.1v-1.8c-0.7,0.6-1.3,1-1.9,1.4
                        s-1.3,0.5-2.1,0.5c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.4-0.5-0.6-0.8s-0.2-0.7-0.2-1.2V115
                        c0-0.1,0-0.3,0-0.4s-0.1-0.3-0.2-0.4s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.7-0.2v-0.4c0.2,0,0.4,0,0.8,0s0.6,0,0.9-0.1
                        c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0,0.5,0c0.1,0,0.2,0,0.2,0.1s0,0.3,0,0.5v5.8c0,0.2,0,0.4,0,0.7s0.1,0.6,0.2,0.9
                        c0.1,0.3,0.3,0.5,0.6,0.7c0.3,0.2,0.6,0.3,1.1,0.3c0.4,0,0.8-0.1,1.3-0.4c0.5-0.3,0.9-0.6,1.4-1v-6c0-0.1-0.1-0.2-0.2-0.3
                        c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.1-0.6-0.2v-0.4c1-0.1,1.8-0.1,2.4-0.2s1-0.1,1.1-0.1
                        c0.1,0,0.1,0,0.2,0.1c0,0.1,0,0.2,0,0.3V121.8z"/>
                    <path class="style00" d="M539.5,115.6c0-0.2,0-0.4-0.1-0.6s-0.1-0.3-0.2-0.4s-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3v-0.4
                        c0.3-0.1,0.5-0.2,0.8-0.2s0.5-0.2,0.7-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1
                        c0.1,0,0.2,0.1,0.2,0.3v1.4c0.3-0.2,0.6-0.4,0.9-0.6s0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.4s0.7-0.1,1.1-0.1
                        c0.6,0,1.1,0.1,1.5,0.4c0.4,0.3,0.7,0.7,0.9,1.3c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.3,1-0.4
                        c0.3-0.1,0.7-0.2,1.1-0.2c0.5,0,0.9,0.1,1.2,0.3s0.6,0.4,0.8,0.7c0.2,0.3,0.4,0.6,0.5,1s0.1,0.8,0.1,1.2v5.8
                        c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.3v0.6c-0.4,0-0.7,0-1.1,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0
                        v-0.6h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-5c0-0.4,0-0.7-0.1-1c0-0.3-0.1-0.6-0.3-0.9s-0.3-0.5-0.6-0.6
                        c-0.3-0.2-0.6-0.2-1-0.2c-0.2,0-0.5,0-0.7,0.1c-0.3,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.2-0.5,0.3v6.6
                        c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0
                        v-0.6h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-5c0-0.4,0-0.7-0.1-1c0-0.3-0.1-0.6-0.3-0.9c-0.1-0.3-0.3-0.5-0.6-0.6
                        c-0.3-0.2-0.6-0.2-1-0.2c-0.2,0-0.5,0-0.7,0.1c-0.3,0.1-0.5,0.2-0.7,0.3s-0.5,0.2-0.7,0.3s-0.4,0.2-0.5,0.3v6.6
                        c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.3,0
                        v-0.6h0.5c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7V115.6z"/>
                    <path class="style00" d="M556.9,122.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.1c0-0.2,0-0.4-0.1-0.6s-0.1-0.3-0.2-0.4
                        s-0.2-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.5-0.3v-0.4c0.3-0.1,0.5-0.2,0.8-0.2s0.5-0.2,0.8-0.2c0.2-0.1,0.4-0.1,0.6-0.2
                        c0.1-0.1,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.3v8.6c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4
                        v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0V122.5z M560.4,108.2c0,0.3-0.1,0.6-0.3,0.8
                        s-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3s-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.3
                        C560.3,107.7,560.4,107.9,560.4,108.2z"/>
                    <path class="style00" d="M564.5,115.8c0-0.2,0-0.4-0.1-0.5s-0.1-0.3-0.2-0.4s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.7-0.3v-0.4
                        c0.5-0.2,1-0.3,1.4-0.5c0.5-0.2,1-0.4,1.5-0.6c0.1,0,0.1,0.1,0.1,0.2v1.5c0.6-0.5,1.3-0.9,1.9-1.2c0.6-0.3,1.3-0.5,2-0.5
                        c0.5,0,0.9,0.1,1.2,0.2c0.3,0.2,0.6,0.4,0.8,0.7c0.2,0.3,0.3,0.6,0.4,1s0.1,0.8,0.1,1.2v5.8c0,0.4,0.1,0.6,0.3,0.7
                        c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0v-0.6h0.4
                        c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-5.8c0-0.6-0.2-1.1-0.5-1.4c-0.4-0.3-0.9-0.5-1.5-0.5c-0.2,0-0.4,0-0.6,0.1
                        s-0.5,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.3s-0.4,0.3-0.6,0.4v6.6c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6
                        c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7
                        V115.8z"/>
                    <path class="style00" d="M585.2,121.8c0,0.2,0.1,0.3,0.2,0.4c0.1,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0v0.5
                        c-0.2,0-0.5,0.1-0.9,0.1c-0.3,0.1-0.7,0.1-1,0.2s-0.6,0.1-0.9,0.2s-0.4,0.1-0.5,0.1l-0.1-0.1v-1.8c-0.7,0.6-1.3,1-1.9,1.4
                        c-0.6,0.4-1.3,0.5-2.1,0.5c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.4-0.5-0.6-0.8c-0.1-0.3-0.2-0.7-0.2-1.2
                        V115c0-0.1,0-0.3,0-0.4s-0.1-0.3-0.2-0.4s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.7-0.2v-0.4c0.2,0,0.4,0,0.8,0c0.3,0,0.6,0,0.9-0.1
                        c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0,0.5,0c0.1,0,0.2,0,0.2,0.1s0,0.3,0,0.5v5.8c0,0.2,0,0.4,0,0.7s0.1,0.6,0.2,0.9
                        c0.1,0.3,0.3,0.5,0.6,0.7c0.3,0.2,0.6,0.3,1.1,0.3c0.4,0,0.8-0.1,1.3-0.4c0.5-0.3,0.9-0.6,1.4-1v-6c0-0.1-0.1-0.2-0.2-0.3
                        c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.1-0.6-0.2v-0.4c1-0.1,1.8-0.1,2.4-0.2c0.6-0.1,1-0.1,1.1-0.1
                        c0.1,0,0.1,0,0.2,0.1c0,0.1,0,0.2,0,0.3V121.8z"/>
                    <path class="style00" d="M589.4,115.6c0-0.2,0-0.4-0.1-0.6s-0.1-0.3-0.2-0.4s-0.2-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.5-0.3v-0.4
                        c0.3-0.1,0.5-0.2,0.8-0.2s0.5-0.2,0.7-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1
                        c0.1,0,0.2,0.1,0.2,0.3v1.4c0.3-0.2,0.6-0.4,0.9-0.6s0.6-0.4,0.9-0.5s0.6-0.3,1-0.4s0.7-0.1,1.1-0.1c0.6,0,1.1,0.1,1.5,0.4
                        c0.4,0.3,0.7,0.7,0.9,1.3c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.3,1-0.4c0.3-0.1,0.7-0.2,1.1-0.2
                        c0.5,0,0.9,0.1,1.2,0.3s0.6,0.4,0.8,0.7s0.4,0.6,0.5,1c0.1,0.4,0.1,0.8,0.1,1.2v5.8c0,0.4,0.1,0.6,0.3,0.7
                        c0.2,0.1,0.5,0.1,0.8,0.1h0.3v0.6c-0.4,0-0.7,0-1.1,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0v-0.6h0.4
                        c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-5c0-0.4,0-0.7-0.1-1c0-0.3-0.1-0.6-0.3-0.9c-0.1-0.3-0.3-0.5-0.6-0.6
                        s-0.6-0.2-1-0.2c-0.2,0-0.5,0-0.7,0.1c-0.3,0.1-0.5,0.2-0.7,0.3s-0.5,0.2-0.7,0.3s-0.4,0.2-0.5,0.3v6.6c0,0.4,0.1,0.6,0.3,0.7
                        c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0v-0.6h0.4
                        c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-5c0-0.4,0-0.7-0.1-1c0-0.3-0.1-0.6-0.3-0.9c-0.1-0.3-0.3-0.5-0.6-0.6
                        c-0.3-0.2-0.6-0.2-1-0.2c-0.2,0-0.5,0-0.7,0.1c-0.3,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.2-0.5,0.3v6.6
                        c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.3,0
                        v-0.6h0.5c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7V115.6z"/>
                    <path class="style00" d="M612.8,122.5h0.6c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.3c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3
                        c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1v-0.3c0.8-0.3,1.5-0.6,1.9-0.9c0.4-0.3,0.7-0.4,0.8-0.4c0.1,0,0.2,0.1,0.2,0.3v2
                        h0c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.6-0.2,1-0.2c0.3,0,0.6,0.1,0.9,0.3
                        c0.3,0.2,0.4,0.5,0.4,0.9c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
                        c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.3s-0.3,0.3-0.5,0.6
                        c-0.1,0.2-0.2,0.5-0.2,0.9v5.3c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.3v0.6c-0.6,0-1.2,0-1.7,0c-0.5,0-1,0-1.5,0
                        c-0.5,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.3,0V122.5z"/>
                    <path class="style00" d="M622,118.1c0-0.7,0.1-1.4,0.4-2.1c0.3-0.7,0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.6-1.2c0.6-0.3,1.3-0.4,2.1-0.4
                        c0.8,0,1.5,0.1,2.2,0.4c0.7,0.2,1.2,0.6,1.7,1.1c0.5,0.5,0.9,1,1.1,1.7c0.3,0.7,0.4,1.4,0.4,2.2c0,0.7-0.1,1.4-0.4,2.1
                        s-0.6,1.2-1.1,1.7c-0.5,0.5-1,0.9-1.6,1.2s-1.3,0.4-2,0.4c-0.7,0-1.5-0.1-2.1-0.4c-0.7-0.2-1.2-0.6-1.7-1.1
                        c-0.5-0.5-0.9-1-1.2-1.7C622.1,119.7,622,118.9,622,118.1z M624,117.9c0,0.5,0.1,1,0.2,1.5c0.1,0.6,0.3,1.1,0.6,1.6s0.6,0.9,1,1.2
                        s0.9,0.5,1.5,0.5c0.6,0,1-0.1,1.4-0.4c0.4-0.3,0.7-0.7,1-1.1c0.3-0.4,0.4-0.9,0.6-1.5c0.1-0.5,0.2-1,0.2-1.5c0-0.5-0.1-1-0.2-1.5
                        c-0.1-0.6-0.3-1.1-0.6-1.6s-0.6-0.9-1-1.2s-0.9-0.5-1.5-0.5c-0.6,0-1,0.1-1.4,0.4s-0.7,0.7-1,1.1s-0.4,0.9-0.5,1.5
                        C624.1,116.9,624,117.4,624,117.9z"/>
                    <path class="style00" d="M634.5,118.1c0-0.7,0.1-1.4,0.4-2.1s0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.6-1.2c0.6-0.3,1.3-0.4,2.1-0.4
                        c0.8,0,1.5,0.1,2.2,0.4s1.2,0.6,1.7,1.1s0.9,1,1.1,1.7s0.4,1.4,0.4,2.2c0,0.7-0.1,1.4-0.4,2.1s-0.6,1.2-1.1,1.7
                        c-0.5,0.5-1,0.9-1.6,1.2s-1.3,0.4-2,0.4c-0.7,0-1.5-0.1-2.1-0.4s-1.2-0.6-1.7-1.1c-0.5-0.5-0.9-1-1.2-1.7S634.5,118.9,634.5,118.1
                        z M636.5,117.9c0,0.5,0.1,1,0.2,1.5c0.1,0.6,0.3,1.1,0.6,1.6s0.6,0.9,1,1.2s0.9,0.5,1.5,0.5c0.6,0,1-0.1,1.4-0.4
                        c0.4-0.3,0.7-0.7,1-1.1c0.3-0.4,0.4-0.9,0.5-1.5c0.1-0.5,0.2-1,0.2-1.5c0-0.5-0.1-1-0.2-1.5c-0.1-0.6-0.3-1.1-0.6-1.6
                        s-0.6-0.9-1-1.2s-0.9-0.5-1.5-0.5c-0.6,0-1,0.1-1.4,0.4c-0.4,0.3-0.7,0.7-1,1.1s-0.4,0.9-0.5,1.5
                        C636.6,116.9,636.5,117.4,636.5,117.9z"/>
                    <path class="style00" d="M653.2,113.1v1.2h-2.9v7.6c0,0.3,0.1,0.4,0.3,0.5s0.5,0.1,0.7,0.1h1v0.6c-0.5,0-1,0-1.4,0c-0.5,0-1,0-1.4,0
                        c-0.5,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.4,0v-0.6h0.9c0.4,0,0.7-0.1,0.8-0.2c0.1-0.2,0.2-0.3,0.2-0.5v-7.4h-1.6V114
                        c0.1-0.1,0.3-0.2,0.5-0.2s0.4-0.2,0.5-0.3c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.2-0.5c0-0.4,0-0.8,0.1-1.2
                        c0.1-0.4,0.2-0.9,0.3-1.3c0.1-0.5,0.3-0.9,0.5-1.3c0.2-0.4,0.5-0.8,0.9-1.2c0.6-0.6,1.2-1.1,1.7-1.3c0.5-0.2,0.8-0.3,1-0.3
                        c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.5c0,0.3-0.1,0.5-0.2,0.6
                        c-0.1,0.2-0.3,0.2-0.6,0.2c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2-0.1-0.3-0.1c-0.1,0-0.3,0-0.4,0
                        c-0.3,0-0.6,0.1-0.8,0.3s-0.4,0.4-0.4,0.7c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.3,0,0.6,0,0.8v3.2H653.2z"/>
                </g>
                <path id="Arrow_20_" class="style00" d="M498.9,191.8c-0.6,0.1-1.4,0.3-1.7,0.9c-4.4,9.1-9,18.2-13.8,27.1c0-0.1-0.1-0.3-0.1-0.4
                    c0.8-4.1,1.1-8.3,1.3-12.5c0.2-5.1,0.2-10.3,0.4-15.4c0.2-10,0.5-20,0.8-30c0.6-20.1,1.2-40.1,1.7-60.2c0.1-5.7,0.3-11.5,0.4-17.2
                    c0-1.5-0.1-3-0.4-4.5c-0.2-1.1-0.4-2.8-1.6-3.3c-0.8-0.4-3.4,0.8-2.3,1.3c0.8,0.4,0.9,2.5,1.1,3.2c0.2,1.3,0.4,2.5,0.3,3.8
                    c-0.1,5-0.2,10-0.3,15c-0.2,9.9-0.5,19.8-0.8,29.7c-0.6,19.7-1.2,39.3-1.6,59c-0.1,5.6-0.3,11.1-0.4,16.7c-0.1,2.7-0.2,5.4-0.4,8.1
                    c-0.3-0.8-0.5-1.6-0.8-2.4c-3.1-13.4-7.1-26.8-14.8-38.3c-0.4-0.6-3.1,0.2-2.8,0.9c5.6,12.5,10.5,25.3,14.8,38.3
                    c0.8,3.5,1.5,6.9,2.3,10.4c0.1,0.4,0.5,0.4,1.1,0.4c0.1,0.4,0.3,0.9,0.4,1.3c0.3,1,2.6,0,2.9-0.6c5.6-10.1,10.8-20.3,15.8-30.7
                    C500.4,191.7,499.1,191.7,498.9,191.8z"/>
            </g>
            
        </g>

        <g class="concrete-content">
            <g id="concrete-circle" v-bind:class="{'opac':over == 'concrete'}">
                <path class="style00" d="M677.6,416.2c-12.9,0-23.3-10.5-23.3-23.3s10.5-23.3,23.3-23.3c12.9,0,23.3,10.5,23.3,23.3
                    S690.4,416.2,677.6,416.2z M677.6,374.5c-10.1,0-18.3,8.2-18.3,18.3s8.2,18.3,18.3,18.3s18.3-8.2,18.3-18.3
                    S687.7,374.5,677.6,374.5z"/>
            </g>

            <g id="concrete-content" v-bind:class="{'opac':over != 'concrete'}">
                <g id="Text_19_">
                    <path class="style00" d="M700.3,500.7v5.2c0,0.4,0.1,0.7,0.2,0.9c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.5,0,0.8,0
                        v0.6c-0.5,0-1.1,0-1.5,0c-0.5,0-1,0-1.5,0c-0.5,0-1.1,0-1.6,0c-0.5,0-1.1,0-1.7,0v-0.6c0.3,0,0.6,0,0.8,0c0.2,0,0.5-0.1,0.7-0.2
                        c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.5,0.2-0.8v-11.4c0-0.4-0.1-0.7-0.2-0.9c-0.1-0.2-0.2-0.4-0.4-0.5s-0.4-0.2-0.6-0.2
                        s-0.5,0-0.8,0v-0.6c0.6,0,1.2,0,1.8,0c0.5,0,1.1,0,1.7,0c0.4,0,0.8,0,1.3,0s1,0,1.8,0c1.1,0,1.9,0.1,2.6,0.4
                        c0.7,0.3,1.2,0.6,1.6,1c0.4,0.4,0.6,0.8,0.8,1.3c0.1,0.5,0.2,0.9,0.2,1.3c0,0.5-0.1,0.9-0.2,1.3c-0.2,0.4-0.4,0.8-0.7,1.1
                        s-0.6,0.6-1,0.8c-0.4,0.2-0.8,0.4-1.2,0.6c1,1.5,1.8,2.7,2.4,3.6c0.6,0.9,1.2,1.7,1.6,2.2c0.4,0.4,0.7,0.7,1.1,1s0.9,0.4,1.4,0.4
                        v0.6c-0.3,0-0.6,0-0.8,0c-0.3,0-0.6,0-0.8,0c-0.3,0-0.6,0-1,0c-0.3,0-0.6,0-1,0c-1-1.1-1.9-2.3-2.7-3.6c-0.8-1.3-1.6-2.5-2.4-3.8
                        H700.3z M705.3,496.6c0-0.5-0.1-0.9-0.2-1.4c-0.1-0.4-0.3-0.8-0.6-1.1s-0.7-0.5-1.1-0.7c-0.5-0.2-1-0.3-1.7-0.3
                        c-0.3,0-0.5,0-0.7,0s-0.4,0.1-0.6,0.1v6.6c0.4,0.1,0.8,0.1,1.2,0.1c0.7,0,1.3-0.1,1.8-0.3c0.5-0.2,0.9-0.5,1.2-0.8
                        c0.3-0.3,0.5-0.7,0.7-1.1C705.3,497.4,705.3,497,705.3,496.6z"/>
                    <path class="style00" d="M714,501.9c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.9c0,0.5,0.1,1,0.3,1.5s0.4,0.9,0.7,1.2s0.6,0.6,1,0.8
                        c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.2-0.1,1.7-0.4c0.5-0.3,0.9-0.6,1.4-1.1l0.3,0.3c-0.3,0.3-0.5,0.7-0.8,1s-0.6,0.6-1,0.8
                        s-0.7,0.4-1.1,0.5c-0.4,0.1-0.8,0.2-1.3,0.2c-0.9,0-1.6-0.2-2.2-0.6c-0.6-0.4-1-0.8-1.3-1.3c-0.3-0.5-0.5-1.1-0.6-1.6
                        c-0.1-0.6-0.2-1-0.2-1.4c0-1.1,0.2-2,0.5-2.7c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.4,1-0.8,1.5-0.9c0.5-0.2,1-0.3,1.4-0.3
                        c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.9,0.4,1.2,0.7s0.7,0.7,0.9,1.1c0.2,0.4,0.3,1,0.3,1.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
                        l-0.2,0.1H714z M718,501.2c0.3,0,0.5-0.1,0.5-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.6-0.2-1.1-0.5-1.5s-0.8-0.6-1.4-0.6
                        c-0.7,0-1.3,0.2-1.7,0.7s-0.7,1.1-0.9,1.9H718z"/>
                    <path class="style00" d="M730.6,499.4c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3
                        c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.2-0.7-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.3,0.4c-0.4,0.2-0.7,0.5-0.9,0.9
                        s-0.4,0.8-0.6,1.3c-0.1,0.5-0.2,0.9-0.2,1.3c0,0.6,0.1,1.1,0.3,1.7c0.2,0.5,0.4,1,0.8,1.4s0.7,0.7,1.2,1c0.5,0.2,1,0.4,1.7,0.4
                        c0.6,0,1.1-0.1,1.5-0.3s0.8-0.4,1.3-0.8l0.4,0.4c-0.6,0.6-1.2,1.2-1.9,1.7c-0.7,0.5-1.5,0.7-2.4,0.7c-0.6,0-1.1-0.1-1.7-0.4
                        s-1-0.6-1.4-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.6-0.4-1.1-0.4-1.7c0-0.8,0.1-1.6,0.4-2.3c0.3-0.7,0.7-1.4,1.2-1.9s1.1-1,1.8-1.3
                        c0.7-0.3,1.4-0.5,2.2-0.5c0.3,0,0.6,0,1,0.1c0.4,0.1,0.7,0.1,1,0.3s0.6,0.3,0.8,0.5C730.5,498.9,730.6,499.1,730.6,499.4z"/>
                    <path class="style00" d="M731.8,498.2c0.4,0,0.9,0,1.3,0c0.4,0,0.9,0,1.3,0c0.3,0,0.7,0,1,0c0.3,0,0.7,0,1,0v0.6c-0.1,0-0.2,0-0.4,0
                        c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2s-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.6c0.1,0.3,0.2,0.6,0.4,1s0.3,0.8,0.5,1.3
                        c0.2,0.4,0.4,0.9,0.5,1.3c0.2,0.4,0.3,0.8,0.4,1.1c0.1,0.3,0.2,0.6,0.3,0.7h0c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.3,0.3-0.7,0.5-1.1
                        c0.2-0.4,0.3-0.8,0.5-1.2c0.2-0.4,0.3-0.8,0.5-1.1s0.2-0.7,0.3-0.9c0.1-0.3,0.1-0.4,0.1-0.5c0-0.2,0-0.4-0.1-0.6
                        c-0.1-0.1-0.2-0.2-0.3-0.3s-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0v-0.6c0.3,0,0.7,0,1.1,0c0.4,0,0.7,0,1.1,0c0.3,0,0.6,0,0.8,0
                        c0.3,0,0.6,0,0.8,0v0.6c-0.5,0-0.9,0.1-1.1,0.4c-0.2,0.3-0.4,0.6-0.6,1l-5.1,11.9c-0.2,0.4-0.4,0.7-0.6,0.9
                        c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.4,0.8-0.4
                        c0.2,0,0.4,0,0.5,0.1c0.1,0,0.3,0.1,0.4,0.3c0.6-1.2,1.1-2.4,1.6-3.6c-0.3-0.8-0.6-1.4-0.8-2s-0.5-1.2-0.7-1.9
                        c-0.3-0.7-0.5-1.3-0.8-1.9c-0.2-0.6-0.4-1.1-0.6-1.5c-0.2-0.4-0.3-0.7-0.3-0.8c-0.2-0.3-0.4-0.5-0.6-0.7c-0.2-0.1-0.5-0.2-0.9-0.2
                        V498.2z"/>
                    <path class="style00" d="M751.8,499.4c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3
                        c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.2-0.7-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.3,0.4c-0.4,0.2-0.7,0.5-0.9,0.9
                        s-0.4,0.8-0.6,1.3c-0.1,0.5-0.2,0.9-0.2,1.3c0,0.6,0.1,1.1,0.3,1.7c0.2,0.5,0.4,1,0.8,1.4s0.7,0.7,1.2,1c0.5,0.2,1,0.4,1.7,0.4
                        c0.6,0,1.1-0.1,1.5-0.3s0.8-0.4,1.3-0.8l0.4,0.4c-0.6,0.6-1.2,1.2-1.9,1.7c-0.7,0.5-1.5,0.7-2.4,0.7c-0.6,0-1.1-0.1-1.7-0.4
                        s-1-0.6-1.4-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.6-0.4-1.1-0.4-1.7c0-0.8,0.1-1.6,0.4-2.3c0.3-0.7,0.7-1.4,1.2-1.9s1.1-1,1.8-1.3
                        c0.7-0.3,1.4-0.5,2.2-0.5c0.3,0,0.6,0,1,0.1c0.4,0.1,0.7,0.1,1,0.3s0.6,0.3,0.8,0.5C751.7,498.9,751.8,499.1,751.8,499.4z"/>
                    <path class="style00" d="M753.3,507.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-12.5c0-0.4,0-0.6-0.1-0.9s-0.1-0.4-0.2-0.5
                        c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.2v-0.4c0.4-0.1,0.9-0.3,1.3-0.5s0.8-0.3,1-0.5c0.1-0.1,0.2-0.1,0.3-0.2
                        s0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v15.5c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0
                        c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0s-0.8,0-1.2,0V507.5z"/>
                    <path class="style00" d="M761.4,501.9c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.9c0,0.5,0.1,1,0.3,1.5s0.4,0.9,0.7,1.2s0.6,0.6,1,0.8
                        c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.2-0.1,1.7-0.4c0.5-0.3,0.9-0.6,1.4-1.1l0.3,0.3c-0.3,0.3-0.5,0.7-0.8,1s-0.6,0.6-1,0.8
                        s-0.7,0.4-1.1,0.5c-0.4,0.1-0.8,0.2-1.3,0.2c-0.9,0-1.6-0.2-2.2-0.6c-0.6-0.4-1-0.8-1.3-1.3c-0.3-0.5-0.5-1.1-0.6-1.6
                        c-0.1-0.6-0.2-1-0.2-1.4c0-1.1,0.2-2,0.5-2.7c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.4,1-0.8,1.5-0.9c0.5-0.2,1-0.3,1.4-0.3
                        c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.9,0.4,1.2,0.7s0.7,0.7,0.9,1.1c0.2,0.4,0.3,1,0.3,1.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
                        l-0.2,0.1H761.4z M765.4,501.2c0.3,0,0.5-0.1,0.5-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.6-0.2-1.1-0.5-1.5s-0.8-0.6-1.4-0.6
                        c-0.7,0-1.3,0.2-1.7,0.7s-0.7,1.1-0.9,1.9H765.4z"/>
                    <path class="style00" d="M779.2,506.2c0,0.2,0,0.4,0,0.5c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.6,0h0.4v0.4h0
                        c-0.2,0-0.4,0.1-0.7,0.2c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.4,0.1-0.5,0.1c-0.1,0-0.2-0.1-0.2-0.2
                        s0-0.3,0-0.4v-1h0c-0.4,0.4-0.9,0.8-1.5,1.1c-0.6,0.3-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.1-1.8-0.4s-1-0.6-1.3-1.1s-0.6-1-0.8-1.6
                        s-0.3-1.2-0.3-1.8c0-0.8,0.1-1.5,0.4-2.2c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.5,1.1-0.9,1.8-1.3s1.4-0.5,2.2-0.5c0.7,0,1.5,0.1,2.1,0.4
                        v-4c0-0.4,0-0.6-0.1-0.9s-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.2v-0.4c0.4-0.1,0.9-0.3,1.3-0.5s0.8-0.3,1-0.5
                        c0.1-0.1,0.2-0.1,0.3-0.2s0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3V506.2z M777.4,500.6c0-0.4-0.1-0.8-0.2-1
                        c-0.2-0.3-0.4-0.5-0.6-0.6s-0.5-0.3-0.8-0.3c-0.3-0.1-0.5-0.1-0.8-0.1c-0.4,0-0.8,0.1-1.2,0.2c-0.4,0.2-0.8,0.4-1.1,0.7
                        s-0.6,0.8-0.8,1.3s-0.3,1.2-0.3,1.9c0,0.6,0.1,1.2,0.3,1.7s0.4,1,0.7,1.4s0.7,0.7,1.1,0.9c0.4,0.2,0.9,0.3,1.4,0.3
                        c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.5-0.1,0.7-0.3s0.4-0.3,0.6-0.4c0.2-0.2,0.2-0.4,0.2-0.6V500.6z"/>
                    <path class="style00" d="M704.4,525.4c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3
                        c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.2-0.7-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.3,0.4c-0.4,0.2-0.7,0.5-0.9,0.9
                        s-0.4,0.8-0.6,1.3c-0.1,0.5-0.2,0.9-0.2,1.3c0,0.6,0.1,1.1,0.3,1.7c0.2,0.5,0.4,1,0.8,1.4s0.7,0.7,1.2,1c0.5,0.2,1,0.4,1.7,0.4
                        c0.6,0,1.1-0.1,1.5-0.3s0.8-0.4,1.3-0.8l0.4,0.4c-0.6,0.6-1.2,1.2-1.9,1.7c-0.7,0.5-1.5,0.7-2.4,0.7c-0.6,0-1.1-0.1-1.7-0.4
                        s-1-0.6-1.4-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.6-0.4-1.1-0.4-1.7c0-0.8,0.1-1.6,0.4-2.3c0.3-0.7,0.7-1.4,1.2-1.9s1.1-1,1.8-1.3
                        c0.7-0.3,1.4-0.5,2.2-0.5c0.3,0,0.6,0,1,0.1c0.4,0.1,0.7,0.1,1,0.3s0.6,0.3,0.8,0.5C704.3,524.9,704.4,525.1,704.4,525.4z"/>
                    <path class="style00" d="M706.2,529.2c0-0.7,0.1-1.4,0.4-2.1c0.3-0.7,0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.6-1.2s1.3-0.4,2.1-0.4
                        c0.8,0,1.5,0.1,2.2,0.4c0.7,0.2,1.2,0.6,1.7,1.1c0.5,0.5,0.9,1,1.1,1.7c0.3,0.7,0.4,1.4,0.4,2.2c0,0.7-0.1,1.4-0.4,2.1
                        s-0.6,1.2-1.1,1.7c-0.5,0.5-1,0.9-1.6,1.2s-1.3,0.4-2,0.4c-0.7,0-1.5-0.1-2.1-0.4c-0.7-0.2-1.2-0.6-1.7-1.1s-0.9-1-1.2-1.7
                        C706.3,530.7,706.2,530,706.2,529.2z M708.2,529c0,0.5,0.1,1,0.2,1.5c0.1,0.6,0.3,1.1,0.6,1.6s0.6,0.9,1,1.2s0.9,0.5,1.5,0.5
                        c0.6,0,1-0.1,1.4-0.4s0.7-0.7,1-1.1s0.4-0.9,0.6-1.5s0.2-1,0.2-1.5c0-0.5-0.1-1-0.2-1.5c-0.1-0.6-0.3-1.1-0.6-1.6s-0.6-0.9-1-1.2
                        s-0.9-0.5-1.5-0.5c-0.6,0-1,0.1-1.4,0.4c-0.4,0.3-0.7,0.7-1,1.1s-0.4,0.9-0.5,1.5S708.2,528.5,708.2,529z"/>
                    <path class="style00" d="M719.8,526.8c0-0.2,0-0.4-0.1-0.5c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.7-0.3
                        v-0.4c0.5-0.2,1-0.3,1.4-0.5c0.5-0.2,1-0.4,1.5-0.6c0.1,0,0.1,0.1,0.1,0.2v1.5c0.6-0.5,1.3-0.9,1.9-1.2c0.6-0.3,1.3-0.5,2-0.5
                        c0.5,0,0.9,0.1,1.2,0.2c0.3,0.2,0.6,0.4,0.8,0.7s0.3,0.6,0.4,1s0.1,0.8,0.1,1.2v5.8c0,0.4,0.1,0.6,0.3,0.7
                        c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0s-0.8,0-1.2,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1
                        c0.2-0.1,0.3-0.3,0.3-0.7V527c0-0.6-0.2-1.1-0.6-1.4s-0.9-0.5-1.5-0.5c-0.2,0-0.4,0-0.6,0.1s-0.5,0.2-0.7,0.3
                        c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.3-0.6,0.4v6.6c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6
                        c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0s-0.8,0-1.2,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7V526.8z"
                        />
                    <path class="style00" d="M739.4,525.4c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3
                        c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.2-0.7-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.3,0.4c-0.4,0.2-0.7,0.5-0.9,0.9
                        s-0.4,0.8-0.6,1.3c-0.1,0.5-0.2,0.9-0.2,1.3c0,0.6,0.1,1.1,0.3,1.7c0.2,0.5,0.4,1,0.8,1.4s0.7,0.7,1.2,1c0.5,0.2,1,0.4,1.7,0.4
                        c0.6,0,1.1-0.1,1.5-0.3s0.8-0.4,1.3-0.8l0.4,0.4c-0.6,0.6-1.2,1.2-1.9,1.7c-0.7,0.5-1.5,0.7-2.4,0.7c-0.6,0-1.1-0.1-1.7-0.4
                        s-1-0.6-1.4-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.6-0.4-1.1-0.4-1.7c0-0.8,0.1-1.6,0.4-2.3c0.3-0.7,0.7-1.4,1.2-1.9s1.1-1,1.8-1.3
                        c0.7-0.3,1.4-0.5,2.2-0.5c0.3,0,0.6,0,1,0.1c0.4,0.1,0.7,0.1,1,0.3s0.6,0.3,0.8,0.5C739.3,524.9,739.4,525.1,739.4,525.4z"/>
                    <path class="style00" d="M740.7,533.5h0.6c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.3c0-0.2,0-0.3-0.1-0.4
                        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1v-0.3c0.8-0.3,1.5-0.6,1.9-0.9s0.7-0.4,0.8-0.4
                        c0.1,0,0.2,0.1,0.2,0.3v2h0c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.6-0.2,1-0.2
                        c0.3,0,0.6,0.1,0.9,0.3s0.4,0.5,0.4,0.9c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.4-0.1
                        c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
                        c-0.2,0.1-0.3,0.3-0.5,0.6c-0.1,0.2-0.2,0.5-0.2,0.9v5.3c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.3v0.6
                        c-0.6,0-1.2,0-1.7,0s-1,0-1.5,0c-0.5,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.3,0V533.5z"/>
                    <path class="style00" d="M751.5,527.9c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.9c0,0.5,0.1,1,0.3,1.5s0.4,0.9,0.7,1.2s0.6,0.6,1,0.8
                        c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.2-0.1,1.7-0.4c0.5-0.3,0.9-0.6,1.4-1.1l0.3,0.3c-0.3,0.3-0.5,0.7-0.8,1s-0.6,0.6-1,0.8
                        s-0.7,0.4-1.1,0.5c-0.4,0.1-0.8,0.2-1.3,0.2c-0.9,0-1.6-0.2-2.2-0.6c-0.6-0.4-1-0.8-1.3-1.3c-0.3-0.5-0.5-1.1-0.6-1.6
                        c-0.1-0.6-0.2-1-0.2-1.4c0-1.1,0.2-2,0.5-2.7c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.4,1-0.8,1.5-0.9c0.5-0.2,1-0.3,1.4-0.3
                        c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.9,0.4,1.2,0.7s0.7,0.7,0.9,1.1c0.2,0.4,0.3,1,0.3,1.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
                        l-0.2,0.1H751.5z M755.5,527.2c0.3,0,0.5-0.1,0.5-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.6-0.2-1.1-0.5-1.5s-0.8-0.6-1.4-0.6
                        c-0.7,0-1.3,0.2-1.7,0.7s-0.7,1.1-0.9,1.9H755.5z"/>
                    <path class="style00" d="M762.6,531.4c0,0.4,0,0.7,0.1,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.3,0.1,0.5,0.1
                        c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.5-0.3,0.8-0.5l0.3,0.3c-0.4,0.4-0.8,0.8-1.3,1.1s-1,0.5-1.7,0.5c-0.7,0-1.3-0.2-1.6-0.5
                        c-0.4-0.4-0.5-0.9-0.5-1.5v-7h-1.5V525c0.3-0.2,0.6-0.4,0.8-0.6c0.2-0.2,0.5-0.4,0.7-0.6c0.2-0.2,0.5-0.5,0.7-0.8
                        c0.2-0.3,0.5-0.7,0.8-1.1h0.3v2.3h3.3v1.2h-3.3V531.4z"/>
                    <path class="style00" d="M768.9,527.9c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.9c0,0.5,0.1,1,0.3,1.5s0.4,0.9,0.7,1.2s0.6,0.6,1,0.8
                        c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.2-0.1,1.7-0.4c0.5-0.3,0.9-0.6,1.4-1.1l0.3,0.3c-0.3,0.3-0.5,0.7-0.8,1s-0.6,0.6-1,0.8
                        s-0.7,0.4-1.1,0.5c-0.4,0.1-0.8,0.2-1.3,0.2c-0.9,0-1.6-0.2-2.2-0.6c-0.6-0.4-1-0.8-1.3-1.3c-0.3-0.5-0.5-1.1-0.6-1.6
                        c-0.1-0.6-0.2-1-0.2-1.4c0-1.1,0.2-2,0.5-2.7c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.4,1-0.8,1.5-0.9c0.5-0.2,1-0.3,1.4-0.3
                        c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.9,0.4,1.2,0.7s0.7,0.7,0.9,1.1c0.2,0.4,0.3,1,0.3,1.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
                        l-0.2,0.1H768.9z M772.9,527.2c0.3,0,0.5-0.1,0.5-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.6-0.2-1.1-0.5-1.5s-0.8-0.6-1.4-0.6
                        c-0.7,0-1.3,0.2-1.7,0.7s-0.7,1.1-0.9,1.9H772.9z"/>
                </g>
                <path id="Arrow_19_" class="style00" d="M684.1,462.1c-3.3-6.9-6.6-13.8-10-20.7c-0.4-0.8-2.7-0.1-2.9,0.6c-0.2,0.9-0.5,1.7-0.8,2.6
                    c-0.7,0.1-1.4,0.5-1.6,0.8c-4.6,11.4-9.3,22.8-13.9,34.2c-0.2,0.6,1,0.5,1.2,0.4c0.6-0.1,1.3-0.3,1.7-0.9
                    c3.6-5.9,6.8-12.1,9.5-18.5c-1.1,18.4,0,36.9-0.5,55.4c-0.2,6.7-0.6,13.5-1.4,20.2c-0.1,0.9,2.8,0.4,2.9-0.4
                    c2.9-23.5,0.7-47.1,1.7-70.6c0.2-4.6,0.5-9.1,1-13.6c0.7-1.9,1.3-3.9,1.9-5.8c2.8,5.7,5.5,11.5,8.3,17.2
                    C681.6,463.7,684.4,462.7,684.1,462.1z"/>
            </g>
        </g>

        <g class="solar-content">

            <g id="solar-circle" v-bind:class="{'opac':over == 'solar'}">
                <path class="style00" d="M1423.7,281.7c-12.9,0-23.3-10.5-23.3-23.3c0-12.9,10.5-23.3,23.3-23.3s23.3,10.5,23.3,23.3
                    C1447,271.2,1436.6,281.7,1423.7,281.7z M1423.7,240c-10.1,0-18.3,8.2-18.3,18.3s8.2,18.3,18.3,18.3s18.3-8.2,18.3-18.3
                    S1433.8,240,1423.7,240z"/>
            </g>

            <g id="solar-content" v-bind:class="{'opac':over != 'solar'}">
                <g id="Text_15_">
                    <path class="style00" d="M1458.8,93.4h0.5c0.1,0.4,0.2,0.8,0.4,1.2c0.2,0.4,0.5,0.8,0.8,1.1c0.3,0.3,0.6,0.6,1,0.8
                        c0.4,0.2,0.8,0.3,1.3,0.3c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.3,1-0.6c0.3-0.3,0.5-0.6,0.7-0.9c0.2-0.4,0.2-0.8,0.2-1.2
                        c0-0.7-0.2-1.2-0.5-1.7c-0.3-0.4-0.7-0.8-1.2-1.1c-0.5-0.3-1-0.6-1.6-0.9c-0.6-0.3-1.1-0.6-1.6-1s-0.9-0.8-1.2-1.4
                        c-0.3-0.6-0.5-1.3-0.5-2.2c0-0.7,0.1-1.4,0.4-1.9c0.2-0.6,0.6-1,1-1.4c0.4-0.4,0.9-0.6,1.5-0.8c0.6-0.2,1.2-0.3,1.9-0.3
                        c0.5,0,0.9,0,1.4,0.1c0.5,0.1,0.9,0.1,1.4,0.2l0.4,2.9h-0.5c-0.1-0.8-0.4-1.4-0.9-1.8c-0.5-0.4-1.2-0.7-2-0.7
                        c-0.9,0-1.5,0.3-2,0.8c-0.5,0.5-0.7,1.2-0.7,2.1c0,0.5,0.1,1,0.3,1.3c0.2,0.4,0.5,0.7,0.8,1c0.3,0.3,0.7,0.5,1.1,0.8
                        c0.4,0.2,0.8,0.4,1.3,0.7c0.4,0.2,0.9,0.5,1.3,0.7s0.8,0.5,1.1,0.9c0.3,0.3,0.6,0.7,0.8,1.2c0.2,0.5,0.3,1,0.3,1.7
                        c0,0.7-0.1,1.4-0.4,1.9c-0.3,0.6-0.7,1-1.2,1.4c-0.5,0.4-1.1,0.7-1.7,0.9s-1.3,0.3-1.9,0.3c-1.2,0-2.3-0.3-3.4-0.8L1458.8,93.4z"
                        />
                    <path class="style00" d="M1469.9,92.1c0-0.7,0.1-1.4,0.4-2.1c0.3-0.7,0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.6-1.2c0.6-0.3,1.3-0.4,2.1-0.4
                        c0.8,0,1.5,0.1,2.2,0.4c0.7,0.2,1.2,0.6,1.7,1.1c0.5,0.5,0.9,1,1.1,1.7c0.3,0.7,0.4,1.4,0.4,2.2c0,0.7-0.1,1.4-0.4,2.1
                        s-0.6,1.2-1.1,1.7c-0.5,0.5-1,0.9-1.6,1.2s-1.3,0.4-2,0.4c-0.7,0-1.5-0.1-2.1-0.4c-0.7-0.2-1.2-0.6-1.7-1.1
                        c-0.5-0.5-0.9-1-1.2-1.7C1470.1,93.7,1469.9,92.9,1469.9,92.1z M1472,91.9c0,0.5,0.1,1,0.2,1.5c0.1,0.6,0.3,1.1,0.6,1.6
                        s0.6,0.9,1,1.2s0.9,0.5,1.5,0.5c0.6,0,1-0.1,1.4-0.4s0.7-0.7,1-1.1s0.4-0.9,0.6-1.5c0.1-0.5,0.2-1,0.2-1.5c0-0.5-0.1-1-0.2-1.5
                        c-0.1-0.6-0.3-1.1-0.6-1.6s-0.6-0.9-1-1.2s-0.9-0.5-1.5-0.5c-0.6,0-1,0.1-1.4,0.4c-0.4,0.3-0.7,0.7-1,1.1s-0.4,0.9-0.5,1.5
                        C1472.1,90.9,1472,91.4,1472,91.9z"/>
                    <path class="style00" d="M1482.2,96.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7V83.2c0-0.4,0-0.6-0.1-0.9
                        c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.2v-0.4c0.4-0.1,0.9-0.3,1.3-0.5s0.8-0.3,1-0.5
                        c0.1-0.1,0.2-0.1,0.3-0.2s0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v15.5c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6
                        c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0V96.5z"/>
                    <path class="style00" d="M1498.2,96.2c-0.3,0.3-0.7,0.6-1,0.8s-0.8,0.4-1.2,0.4c-0.5,0-0.8-0.2-1-0.5c-0.2-0.4-0.3-0.7-0.4-1.1
                        c-0.5,0.5-1.1,0.9-1.6,1.2s-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.2-1.8-0.6s-0.7-0.9-0.7-1.5c0-0.4,0.1-0.8,0.3-1.1
                        c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.3,1-0.5,1.8-0.8c0.8-0.3,1.7-0.6,3-1v-1.5c0-1.3-0.7-1.9-2.2-1.9c-0.5,0-0.9,0.1-1.2,0.4
                        c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3-0.1,0.6-0.4,0.7c-0.3,0.2-0.6,0.2-0.8,0.2c-0.2,0-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
                        c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.7-0.4,1.1-0.6c0.2-0.1,0.4-0.2,0.5-0.3
                        c0.2-0.1,0.3-0.2,0.5-0.2c0.1-0.1,0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.2,0.9,0.4
                        c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.5,0.1,0.8,0.2,1c0.1,0.2,0.3,0.3,0.6,0.3c0.1,0,0.3,0,0.4-0.1
                        c0.1-0.1,0.3-0.2,0.4-0.3L1498.2,96.2z M1494.5,92.1c-0.3,0.1-0.7,0.2-1.1,0.4c-0.5,0.1-0.9,0.3-1.3,0.5c-0.4,0.2-0.8,0.4-1.1,0.7
                        c-0.3,0.3-0.5,0.6-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.5,1.1,0.5c0.5,0,1-0.1,1.3-0.4c0.4-0.3,0.7-0.6,1.1-0.9V92.1z"/>
                    <path class="style00" d="M1499.5,96.5h0.6c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.3c0-0.2,0-0.3-0.1-0.4s-0.2-0.2-0.3-0.3
                        c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1v-0.3c0.8-0.3,1.5-0.6,1.9-0.9c0.4-0.3,0.7-0.4,0.8-0.4c0.1,0,0.2,0.1,0.2,0.3v2
                        h0c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.6-0.2,1-0.2c0.3,0,0.6,0.1,0.9,0.3
                        c0.3,0.2,0.4,0.5,0.4,0.9c0,0.3-0.1,0.6-0.3,0.8s-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
                        c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.3s-0.3,0.3-0.5,0.6
                        c-0.1,0.2-0.2,0.5-0.2,0.9v5.3c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.3v0.6c-0.6,0-1.2,0-1.7,0c-0.5,0-1,0-1.5,0
                        c-0.5,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.3,0V96.5z"/>
                    <path class="style00" d="M1518,101.1c0,0.3,0.1,0.4,0.3,0.5s0.5,0.1,0.7,0.1h1v0.6c-0.5,0-1,0-1.4,0c-0.5,0-1,0-1.4,0
                        c-0.5,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.4,0v-0.6h0.9c0.4,0,0.7-0.1,0.8-0.2c0.1-0.2,0.2-0.3,0.2-0.5V90.2c0-0.4,0-0.7-0.1-1
                        c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2V88c0.4-0.2,0.9-0.4,1.3-0.6
                        c0.4-0.2,0.9-0.4,1.3-0.6c0,0,0.1,0,0.1-0.1s0.1,0,0.1,0c0,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.1,0.2v1.6c0.2-0.2,0.5-0.4,0.7-0.6
                        c0.3-0.2,0.5-0.4,0.8-0.5c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.6-0.1,1-0.1c0.6,0,1.2,0.1,1.7,0.4c0.5,0.3,0.9,0.6,1.3,1.1
                        s0.6,1,0.8,1.5c0.2,0.6,0.3,1.2,0.3,1.7c0,0.8-0.1,1.5-0.4,2.2c-0.3,0.7-0.7,1.3-1.2,1.9s-1.1,1-1.8,1.3s-1.4,0.5-2.2,0.5
                        c-0.3,0-0.6,0-1-0.1c-0.4-0.1-0.7-0.2-1-0.3V101.1z M1523.7,92.5c0-0.5-0.1-1.1-0.2-1.6c-0.2-0.5-0.4-1-0.7-1.3s-0.7-0.7-1.2-0.9
                        c-0.5-0.2-1-0.4-1.6-0.4c-0.4,0-0.7,0.1-1.1,0.2c-0.4,0.2-0.7,0.3-0.9,0.5v6.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.3,0.5,0.5
                        c0.2,0.2,0.4,0.3,0.7,0.4s0.6,0.2,0.9,0.2c0.5,0,1-0.1,1.4-0.4c0.4-0.3,0.8-0.6,1-1c0.3-0.4,0.5-0.9,0.6-1.3
                        C1523.6,93.5,1523.7,93,1523.7,92.5z"/>
                    <path class="style00" d="M1536.9,96.2c-0.3,0.3-0.7,0.6-1,0.8s-0.8,0.4-1.2,0.4c-0.5,0-0.8-0.2-1-0.5c-0.2-0.4-0.3-0.7-0.4-1.1
                        c-0.5,0.5-1.1,0.9-1.6,1.2s-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.2-1.8-0.6s-0.7-0.9-0.7-1.5c0-0.4,0.1-0.8,0.3-1.1
                        c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.3,1-0.5,1.8-0.8c0.8-0.3,1.7-0.6,3-1v-1.5c0-1.3-0.7-1.9-2.2-1.9c-0.5,0-0.9,0.1-1.2,0.4
                        c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3-0.1,0.6-0.4,0.7c-0.3,0.2-0.6,0.2-0.8,0.2c-0.2,0-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
                        c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.7-0.4,1.1-0.6c0.2-0.1,0.4-0.2,0.5-0.3
                        c0.2-0.1,0.3-0.2,0.5-0.2c0.1-0.1,0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.2,0.9,0.4
                        c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.5,0.1,0.8,0.2,1c0.1,0.2,0.3,0.3,0.6,0.3c0.1,0,0.3,0,0.4-0.1
                        c0.1-0.1,0.3-0.2,0.4-0.3L1536.9,96.2z M1533.2,92.1c-0.3,0.1-0.7,0.2-1.1,0.4c-0.5,0.1-0.9,0.3-1.3,0.5c-0.4,0.2-0.8,0.4-1.1,0.7
                        c-0.3,0.3-0.5,0.6-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.5,1.1,0.5c0.5,0,1-0.1,1.3-0.4c0.4-0.3,0.7-0.6,1.1-0.9V92.1z"/>
                    <path class="style00" d="M1539.7,89.8c0-0.2,0-0.4-0.1-0.5c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.7-0.3
                        v-0.4c0.5-0.2,1-0.3,1.4-0.5c0.5-0.2,1-0.4,1.5-0.6c0.1,0,0.1,0.1,0.1,0.2v1.5c0.6-0.5,1.3-0.9,1.9-1.2c0.6-0.3,1.3-0.5,2-0.5
                        c0.5,0,0.9,0.1,1.2,0.2c0.3,0.2,0.6,0.4,0.8,0.7c0.2,0.3,0.3,0.6,0.4,1s0.1,0.8,0.1,1.2v5.8c0,0.4,0.1,0.6,0.3,0.7
                        c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0v-0.6h0.4
                        c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-5.8c0-0.6-0.2-1.1-0.6-1.4s-0.9-0.5-1.5-0.5c-0.2,0-0.4,0-0.6,0.1
                        s-0.5,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.3-0.6,0.4v6.6c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6
                        c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7
                        V89.8z"/>
                    <path class="style00" d="M1552.7,90.9c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.9c0,0.5,0.1,1,0.3,1.5s0.4,0.9,0.7,1.2
                        c0.3,0.4,0.6,0.6,1,0.8c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.2-0.1,1.7-0.4c0.5-0.3,0.9-0.6,1.4-1.1l0.3,0.3c-0.3,0.3-0.5,0.7-0.8,1
                        c-0.3,0.3-0.6,0.6-1,0.8c-0.3,0.2-0.7,0.4-1.1,0.6c-0.4,0.1-0.8,0.2-1.3,0.2c-0.9,0-1.6-0.2-2.2-0.6c-0.6-0.4-1-0.8-1.3-1.3
                        c-0.3-0.5-0.5-1.1-0.6-1.6c-0.1-0.6-0.2-1-0.2-1.4c0-1.1,0.2-2,0.5-2.7c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.4,1-0.8,1.5-0.9
                        c0.5-0.2,1-0.3,1.4-0.3c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.9,0.4,1.2,0.7s0.7,0.7,0.9,1.1c0.2,0.4,0.3,1,0.3,1.6c0,0.1,0,0.1,0,0.2
                        c0,0.1,0,0.1,0,0.2l-0.2,0.1H1552.7z M1556.7,90.1c0.3,0,0.5-0.1,0.5-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.6-0.2-1.1-0.5-1.5
                        s-0.8-0.6-1.4-0.6c-0.7,0-1.3,0.2-1.7,0.7s-0.7,1.1-0.9,1.9H1556.7z"/>
                    <path class="style00" d="M1560.8,96.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7V83.2c0-0.4,0-0.6-0.1-0.9
                        c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.2v-0.4c0.4-0.1,0.9-0.3,1.3-0.5s0.8-0.3,1-0.5
                        c0.1-0.1,0.2-0.1,0.3-0.2s0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v15.5c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6
                        c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0V96.5z"/>
                    <path class="style00" d="M1567.7,93.9c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.3,0.3,0.6,0.6,0.9c0.2,0.2,0.5,0.4,0.8,0.6
                        c0.3,0.1,0.7,0.2,1.1,0.2c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
                        c0-0.4-0.1-0.8-0.4-1.1c-0.2-0.3-0.5-0.5-0.9-0.7c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.3-0.7-0.6-0.9-1
                        c-0.2-0.4-0.4-0.9-0.4-1.5c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.6-0.9c0.3-0.3,0.6-0.5,1.1-0.7c0.4-0.2,1-0.3,1.6-0.3
                        c0.4,0,0.8,0.1,1.2,0.2c0.5,0.1,0.9,0.3,1.3,0.5l0.2,2.3h-0.5c-0.1-0.7-0.4-1.3-0.8-1.7c-0.4-0.4-1-0.6-1.7-0.6
                        c-0.4,0-0.9,0.1-1.2,0.4c-0.4,0.3-0.5,0.6-0.5,1.1c0,0.4,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.5,1,0.7c0.4,0.2,0.8,0.4,1.3,0.6
                        s0.9,0.4,1.3,0.6c0.4,0.2,0.7,0.6,1,0.9c0.3,0.4,0.4,0.9,0.4,1.4c0,0.4-0.1,0.7-0.2,1.1s-0.4,0.7-0.7,1s-0.7,0.5-1.2,0.7
                        c-0.5,0.2-1.1,0.3-1.7,0.3c-0.4,0-0.9-0.1-1.4-0.2s-0.9-0.3-1.3-0.6l-0.2-2.6L1567.7,93.9z"/>
                </g>
                <path id="Arrow_15_" class="style00" d="M1444.4,203.7c-2.2,5.5-4.4,11.1-6.6,16.6c-0.9-7.5-1.6-14.9-1.3-22.5c0.3-9.2,1.4-18.4,2-27.6
                    c1.2-18.3,1.5-36.6,1-54.9c-0.3-10.4-0.8-20.8-1.6-31.2c-0.1-0.7-3-0.2-2.9,0.8c1.4,18.4,2.1,36.8,1.8,55.2
                    c-0.1,9.2-0.5,18.5-1,27.7c-0.5,9.1-1.5,18.2-2,27.3c-0.4,8.5,0.2,16.8,1.1,25.2c-3-4.8-5.5-9.9-7.5-15.1c-0.3-0.8-3.2,0-2.9,0.8
                    c2.8,7.1,6.4,13.9,10.8,20.1c0.1,0.1,0.2,0.2,0.4,0.2c0.4,0.6,2.6-0.2,2.8-0.8c0.4-1,0.8-2.1,1.2-3.1c3.3-6.1,5.8-12.5,7.6-19.2
                    C1447.5,202.4,1444.7,203,1444.4,203.7z"/>
            </g>

            
        </g>

        <g class="fsc-content">
            
            <g id="fsc-circle" v-bind:class="{'opac':over == 'fsc'}">
                <path class="style00" d="M1859.1,298.9c-12.9,0-23.3-10.5-23.3-23.3c0-12.9,10.5-23.3,23.3-23.3c12.9,0,23.3,10.5,23.3,23.3
                    C1882.5,288.4,1872,298.9,1859.1,298.9z M1859.1,257.3c-10.1,0-18.3,8.2-18.3,18.3c0,10.1,8.2,18.3,18.3,18.3s18.3-8.2,18.3-18.3
                    C1877.5,265.5,1869.2,257.3,1859.1,257.3z"/>
            </g>

            <g id="fsc-content" v-bind:class="{'opac':over != 'fsc'}">
                <g id="Text_16_">
                    <path class="style00" d="M1780,97.9c0,0.4,0.1,0.7,0.2,0.9c0.1,0.2,0.2,0.4,0.4,0.5c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.5,0,0.8,0v0.6
                        c-0.6,0-1.1,0-1.6,0c-0.5,0-1,0-1.6,0c-0.5,0-1,0-1.5,0c-0.5,0-1.1,0-1.6,0v-0.6c0.5,0,0.9,0,1.2-0.1c0.3-0.1,0.5-0.2,0.6-0.4
                        s0.2-0.4,0.3-0.8c0-0.3,0.1-0.8,0.1-1.3V87c0-0.5,0-0.8-0.1-1.1c-0.1-0.3-0.2-0.4-0.3-0.6s-0.4-0.2-0.7-0.3
                        c-0.3,0-0.6-0.1-1.1-0.1v-0.6c0.8,0,1.6,0,2.4,0c0.8,0,1.6,0,2.4,0c1,0,2.1,0,3.1,0c1,0,2,0,3,0l0.1,3.2l-0.5,0.1
                        c-0.1-0.5-0.2-0.9-0.3-1.2c-0.2-0.3-0.4-0.6-0.7-0.8c-0.3-0.2-0.6-0.3-1.1-0.4c-0.4-0.1-1-0.1-1.6-0.1h-2.7v6.4h2.7
                        c0.5,0,0.8,0,1.1-0.1s0.5-0.1,0.6-0.3c0.1-0.1,0.3-0.3,0.3-0.6c0.1-0.2,0.1-0.6,0.2-1h0.5c0,0.1,0,0.3,0,0.4c0,0.2,0,0.4,0,0.6
                        s0,0.5,0,0.7s0,0.5,0,0.7c0,0.4,0,0.8,0,1.2c0,0.5,0,0.8,0.1,1.1h-0.4c-0.1-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.2-0.5-0.3-0.6
                        c-0.2-0.2-0.4-0.3-0.6-0.3s-0.7-0.1-1.1-0.1h-2.7V97.9z"/>
                    <path class="style00" d="M1788.5,96.4h0.5c0.1,0.4,0.2,0.8,0.4,1.2c0.2,0.4,0.5,0.8,0.8,1.1c0.3,0.3,0.6,0.6,1,0.8
                        c0.4,0.2,0.8,0.3,1.3,0.3c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.3,1-0.6c0.3-0.3,0.5-0.6,0.7-0.9c0.2-0.4,0.2-0.8,0.2-1.2
                        c0-0.7-0.2-1.2-0.5-1.7c-0.3-0.4-0.7-0.8-1.2-1.1c-0.5-0.3-1-0.6-1.6-0.9c-0.6-0.3-1.1-0.6-1.6-1s-0.9-0.8-1.2-1.4
                        c-0.3-0.6-0.5-1.3-0.5-2.2c0-0.7,0.1-1.4,0.4-1.9c0.2-0.6,0.6-1,1-1.4c0.4-0.4,0.9-0.6,1.5-0.8c0.6-0.2,1.2-0.3,1.9-0.3
                        c0.5,0,0.9,0,1.4,0.1c0.5,0.1,0.9,0.1,1.4,0.2l0.4,2.9h-0.5c-0.1-0.8-0.4-1.4-0.9-1.8c-0.5-0.4-1.2-0.7-2-0.7
                        c-0.9,0-1.5,0.3-2,0.8c-0.5,0.5-0.7,1.2-0.7,2.1c0,0.5,0.1,1,0.3,1.3c0.2,0.4,0.5,0.7,0.8,1c0.3,0.3,0.7,0.5,1.1,0.8
                        c0.4,0.2,0.8,0.4,1.3,0.7c0.4,0.2,0.9,0.5,1.3,0.7c0.4,0.2,0.8,0.5,1.1,0.9s0.6,0.7,0.8,1.2c0.2,0.5,0.3,1,0.3,1.7
                        c0,0.7-0.1,1.4-0.4,1.9s-0.7,1-1.2,1.4c-0.5,0.4-1.1,0.7-1.7,0.9c-0.6,0.2-1.3,0.3-1.9,0.3c-1.2,0-2.3-0.3-3.4-0.8L1788.5,96.4z"
                        />
                    <path class="style00" d="M1814,88.4h-0.6c-0.1-0.7-0.3-1.2-0.6-1.7c-0.3-0.5-0.6-0.8-1-1.1c-0.4-0.3-0.9-0.5-1.4-0.7s-1.1-0.2-1.8-0.2
                        c-0.6,0-1.3,0.1-2.1,0.4c-0.7,0.2-1.4,0.6-2.1,1.2c-0.6,0.5-1.2,1.3-1.6,2.2c-0.4,0.9-0.6,2-0.6,3.4c0,1.1,0.2,2.2,0.6,3.1
                        c0.4,1,0.9,1.8,1.5,2.5c0.6,0.7,1.3,1.2,2.1,1.6c0.8,0.4,1.6,0.6,2.5,0.6c0.6,0,1.1-0.1,1.6-0.3c0.5-0.2,1-0.4,1.3-0.8
                        s0.7-0.7,1-1.2c0.3-0.5,0.5-1,0.6-1.6l0.5,0.1l-0.5,3.3c-0.2,0.1-0.6,0.3-1,0.4c-0.4,0.1-0.8,0.3-1.3,0.4s-0.9,0.2-1.4,0.3
                        s-0.9,0.1-1.3,0.1c-1.3,0-2.5-0.2-3.6-0.6c-1.1-0.4-2-1-2.7-1.7s-1.3-1.6-1.7-2.7c-0.4-1-0.6-2.2-0.6-3.4c0-1.2,0.2-2.2,0.7-3.2
                        s1.1-1.8,1.9-2.5c0.8-0.7,1.7-1.2,2.7-1.6c1-0.4,2.1-0.6,3.2-0.6c0.9,0,1.8,0.1,2.7,0.4s1.7,0.5,2.6,0.8L1814,88.4z"/>
                    <path class="style00" d="M1787.7,121.6c0.1,0.2,0.2,0.5,0.3,0.8c0.1,0.3,0.2,0.6,0.3,1c0.1,0.3,0.2,0.6,0.3,0.9
                        c0.1,0.3,0.2,0.5,0.3,0.6c0.2,0.2,0.4,0.4,0.7,0.5s0.7,0.1,1,0.1v0.6c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0c-0.6,0-1.1,0-1.6,0
                        c-0.5,0-1.1,0-1.6,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1s0.4-0.3,0.4-0.6c0-0.1,0-0.3-0.1-0.6c-0.1-0.3-0.2-0.5-0.2-0.7l-1.1-3.3h-5.3
                        l-0.5,1.6c-0.1,0.5-0.3,1-0.4,1.4c-0.1,0.5-0.2,0.9-0.2,1.2c0,0.3,0,0.5,0.1,0.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.1
                        c0.2,0,0.4,0,0.6,0h0.5v0.6c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.3,0c-0.4,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.4,0v-0.6
                        c0.3,0,0.6-0.1,0.8-0.1c0.2-0.1,0.4-0.1,0.5-0.2s0.3-0.3,0.4-0.4c0.1-0.2,0.2-0.4,0.4-0.8c0.2-0.4,0.3-0.8,0.5-1.2
                        c0.1-0.4,0.3-0.8,0.4-1.3l2.9-8c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.4,0.2-0.7,0.2-1.1c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3
                        c0.2-0.1,0.5-0.3,0.7-0.4c0.2-0.1,0.4-0.3,0.6-0.6h0.4L1787.7,121.6z M1780.1,119.3h4.6l-2.4-6.6h0L1780.1,119.3z"/>
                    <path class="style00" d="M1800.3,117.3c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3
                        c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.2-0.7-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.3,0.4c-0.4,0.2-0.7,0.6-0.9,0.9
                        s-0.4,0.8-0.6,1.3c-0.1,0.5-0.2,0.9-0.2,1.3c0,0.6,0.1,1.1,0.3,1.7c0.2,0.5,0.4,1,0.8,1.4c0.3,0.4,0.7,0.7,1.2,1
                        c0.5,0.2,1,0.4,1.7,0.4c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.4,1.3-0.8l0.4,0.4c-0.6,0.6-1.2,1.2-1.9,1.7s-1.5,0.7-2.4,0.7
                        c-0.6,0-1.1-0.1-1.7-0.4s-1-0.6-1.4-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.6-0.4-1.1-0.4-1.7c0-0.8,0.1-1.6,0.4-2.3
                        c0.3-0.7,0.7-1.4,1.2-1.9s1.1-1,1.8-1.3c0.7-0.3,1.4-0.5,2.2-0.5c0.3,0,0.6,0,1,0.1c0.4,0.1,0.7,0.1,1,0.3
                        c0.3,0.1,0.6,0.3,0.8,0.5C1800.2,116.8,1800.3,117,1800.3,117.3z"/>
                    <path class="style00" d="M1810.3,117.3c0,0.3-0.1,0.5-0.2,0.7s-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3c-0.1-0.2-0.3-0.4-0.5-0.6
                        c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.2-0.7-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.3,0.4c-0.4,0.2-0.7,0.6-0.9,0.9s-0.4,0.8-0.6,1.3
                        s-0.2,0.9-0.2,1.3c0,0.6,0.1,1.1,0.3,1.7c0.2,0.5,0.4,1,0.8,1.4c0.3,0.4,0.7,0.7,1.2,1c0.5,0.2,1,0.4,1.7,0.4
                        c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.4,1.3-0.8l0.4,0.4c-0.6,0.6-1.2,1.2-1.9,1.7c-0.7,0.5-1.5,0.7-2.4,0.7
                        c-0.6,0-1.1-0.1-1.7-0.4s-1-0.6-1.4-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.6-0.4-1.1-0.4-1.7c0-0.8,0.1-1.6,0.4-2.3
                        c0.3-0.7,0.7-1.4,1.2-1.9s1.1-1,1.8-1.3s1.4-0.5,2.2-0.5c0.3,0,0.6,0,1,0.1c0.4,0.1,0.7,0.1,1,0.3c0.3,0.1,0.6,0.3,0.8,0.5
                        C1810.2,116.8,1810.3,117,1810.3,117.3z"/>
                    <path class="style00" d="M1812,121.1c0-0.7,0.1-1.4,0.4-2.1c0.3-0.7,0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.6-1.2c0.6-0.3,1.3-0.4,2.1-0.4
                        c0.8,0,1.5,0.1,2.2,0.4c0.7,0.2,1.2,0.6,1.7,1.1c0.5,0.5,0.9,1,1.1,1.7c0.3,0.7,0.4,1.4,0.4,2.2c0,0.7-0.1,1.4-0.4,2.1
                        s-0.6,1.2-1.1,1.7c-0.5,0.5-1,0.9-1.6,1.2s-1.3,0.4-2,0.4c-0.7,0-1.5-0.1-2.1-0.4c-0.7-0.2-1.2-0.6-1.7-1.1
                        c-0.5-0.5-0.9-1-1.2-1.7C1812.2,122.7,1812,121.9,1812,121.1z M1814.1,120.9c0,0.5,0.1,1,0.2,1.5c0.1,0.6,0.3,1.1,0.6,1.6
                        s0.6,0.9,1,1.2s0.9,0.5,1.5,0.5c0.6,0,1-0.1,1.4-0.4c0.4-0.3,0.7-0.7,1-1.1c0.3-0.4,0.4-0.9,0.6-1.5c0.1-0.5,0.2-1,0.2-1.5
                        c0-0.5-0.1-1-0.2-1.5c-0.1-0.6-0.3-1.1-0.6-1.6s-0.6-0.9-1-1.2s-0.9-0.5-1.5-0.5c-0.6,0-1,0.1-1.4,0.4s-0.7,0.7-1,1.1
                        s-0.4,0.9-0.5,1.5C1814.1,119.9,1814.1,120.4,1814.1,120.9z"/>
                    <path class="style00" d="M1824,116.1c0.4,0,0.9,0,1.3,0c0.4,0,0.9,0,1.3,0c0.3,0,0.7,0,1,0c0.3,0,0.7,0,1,0v0.6c-0.1,0-0.2,0-0.4,0
                        c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0.1,0.6c0.1,0.3,0.2,0.6,0.4,1
                        c0.2,0.4,0.3,0.8,0.5,1.3c0.2,0.4,0.4,0.9,0.5,1.3c0.2,0.4,0.3,0.8,0.4,1.1c0.1,0.3,0.2,0.6,0.3,0.7h0c0.1-0.2,0.2-0.5,0.3-0.8
                        c0.1-0.3,0.3-0.7,0.5-1.1s0.3-0.8,0.5-1.2c0.2-0.4,0.3-0.8,0.5-1.1c0.1-0.4,0.2-0.7,0.3-0.9s0.1-0.4,0.1-0.6c0-0.2,0-0.4-0.1-0.6
                        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0v-0.6c0.3,0,0.7,0,1.1,0c0.4,0,0.7,0,1.1,0
                        c0.3,0,0.6,0,0.8,0c0.3,0,0.6,0,0.8,0v0.6c-0.5,0-0.9,0.1-1.1,0.4c-0.2,0.3-0.4,0.6-0.6,1l-5.1,11.9c-0.2,0.4-0.4,0.7-0.6,0.9
                        c-0.2,0.2-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.4,0.8-0.4
                        c0.2,0,0.4,0,0.5,0.1c0.1,0,0.3,0.1,0.4,0.3c0.6-1.2,1.1-2.4,1.6-3.6c-0.3-0.8-0.6-1.4-0.8-2c-0.2-0.6-0.5-1.2-0.7-1.9
                        c-0.3-0.7-0.5-1.3-0.8-1.9c-0.2-0.6-0.4-1.1-0.6-1.5s-0.3-0.7-0.3-0.8c-0.2-0.3-0.4-0.5-0.6-0.7c-0.2-0.1-0.5-0.2-0.9-0.2V116.1z"
                        />
                    <path class="style00" d="M1845.2,125.2c-0.3,0.3-0.7,0.6-1,0.8s-0.8,0.4-1.2,0.4c-0.5,0-0.8-0.2-1-0.5s-0.3-0.7-0.4-1.1
                        c-0.5,0.5-1.1,0.9-1.6,1.2s-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.2-1.8-0.6s-0.7-0.9-0.7-1.5c0-0.4,0.1-0.8,0.3-1.1
                        c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.3,1-0.5,1.8-0.8c0.8-0.3,1.7-0.6,3-1v-1.5c0-1.3-0.7-1.9-2.2-1.9c-0.5,0-0.9,0.1-1.2,0.4
                        s-0.5,0.7-0.5,1.2c0,0.3-0.1,0.6-0.4,0.7c-0.3,0.2-0.6,0.2-0.8,0.2c-0.2,0-0.3-0.1-0.3-0.2s-0.1-0.2-0.1-0.3
                        c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.5-0.4,0.8-0.6s0.7-0.4,1.1-0.6c0.2-0.1,0.4-0.2,0.5-0.3
                        c0.2-0.1,0.3-0.2,0.5-0.2c0.1-0.1,0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.2,0.9,0.4
                        c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.5,0.1,0.8,0.2,1c0.1,0.2,0.3,0.3,0.6,0.3c0.1,0,0.3,0,0.4-0.1
                        s0.3-0.2,0.4-0.3L1845.2,125.2z M1841.6,121.1c-0.3,0.1-0.7,0.2-1.1,0.4c-0.5,0.1-0.9,0.3-1.3,0.5s-0.8,0.4-1.1,0.7
                        c-0.3,0.3-0.5,0.6-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.5,1.1,0.5c0.5,0,1-0.1,1.3-0.4c0.4-0.3,0.7-0.6,1.1-0.9V121.1z"/>
                    <path class="style00" d="M1784.1,143.3c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3
                        c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.2-0.7-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.3,0.4c-0.4,0.2-0.7,0.6-0.9,0.9
                        s-0.4,0.8-0.6,1.3c-0.1,0.5-0.2,0.9-0.2,1.3c0,0.6,0.1,1.1,0.3,1.7c0.2,0.5,0.4,1,0.8,1.4c0.3,0.4,0.7,0.7,1.2,1
                        c0.5,0.2,1,0.4,1.7,0.4c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.4,1.3-0.8l0.4,0.4c-0.6,0.6-1.2,1.2-1.9,1.7s-1.5,0.7-2.4,0.7
                        c-0.6,0-1.1-0.1-1.7-0.4s-1-0.6-1.4-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.6-0.4-1.1-0.4-1.7c0-0.8,0.1-1.6,0.4-2.3
                        c0.3-0.7,0.7-1.4,1.2-1.9s1.1-1,1.8-1.3c0.7-0.3,1.4-0.5,2.2-0.5c0.3,0,0.6,0,1,0.1c0.4,0.1,0.7,0.1,1,0.3
                        c0.3,0.1,0.6,0.3,0.8,0.5C1784,142.8,1784.1,143,1784.1,143.3z"/>
                    <path class="style00" d="M1785.9,147.1c0-0.7,0.1-1.4,0.4-2.1c0.3-0.7,0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.6-1.2c0.6-0.3,1.3-0.4,2.1-0.4
                        c0.8,0,1.5,0.1,2.2,0.4c0.7,0.2,1.2,0.6,1.7,1.1c0.5,0.5,0.9,1,1.1,1.7c0.3,0.7,0.4,1.4,0.4,2.2c0,0.7-0.1,1.4-0.4,2.1
                        s-0.6,1.2-1.1,1.7c-0.5,0.5-1,0.9-1.6,1.2s-1.3,0.4-2,0.4c-0.7,0-1.5-0.1-2.1-0.4c-0.7-0.2-1.2-0.6-1.7-1.1
                        c-0.5-0.5-0.9-1-1.2-1.7C1786,148.7,1785.9,147.9,1785.9,147.1z M1787.9,146.9c0,0.5,0.1,1,0.2,1.5c0.1,0.6,0.3,1.1,0.6,1.6
                        s0.6,0.9,1,1.2s0.9,0.5,1.5,0.5c0.6,0,1-0.1,1.4-0.4c0.4-0.3,0.7-0.7,1-1.1c0.3-0.4,0.4-0.9,0.6-1.5c0.1-0.5,0.2-1,0.2-1.5
                        c0-0.5-0.1-1-0.2-1.5c-0.1-0.6-0.3-1.1-0.6-1.6s-0.6-0.9-1-1.2s-0.9-0.5-1.5-0.5c-0.6,0-1,0.1-1.4,0.4s-0.7,0.7-1,1.1
                        s-0.4,0.9-0.5,1.5C1788,145.9,1787.9,146.4,1787.9,146.9z"/>
                    <path class="style00" d="M1798.1,151.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-12.5c0-0.4,0-0.6-0.1-0.9
                        c0-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2v-0.4c0.4-0.1,0.9-0.3,1.3-0.5s0.8-0.3,1-0.5
                        c0.1-0.1,0.2-0.1,0.3-0.2s0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v15.5c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6
                        c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0V151.5z"/>
                    <path class="style00" d="M1814,150.8c0,0.2,0.1,0.3,0.2,0.4c0.1,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0v0.5
                        c-0.2,0-0.5,0.1-0.9,0.1c-0.3,0.1-0.7,0.1-1,0.2s-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.1-0.6,0.1l-0.1-0.1v-1.8c-0.7,0.6-1.3,1-1.9,1.4
                        s-1.3,0.5-2.1,0.5c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.4-0.5-0.6-0.8s-0.2-0.7-0.2-1.2V144
                        c0-0.1,0-0.3,0-0.4s-0.1-0.3-0.2-0.4s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.7-0.2v-0.4c0.2,0,0.4,0,0.8,0s0.6,0,0.9-0.1
                        c0.3,0,0.6,0,0.8,0c0.2,0,0.4,0,0.5,0c0.1,0,0.2,0,0.2,0.1s0,0.3,0,0.5v5.8c0,0.2,0,0.4,0,0.7s0.1,0.6,0.2,0.9
                        c0.1,0.3,0.3,0.5,0.6,0.7c0.3,0.2,0.6,0.3,1.1,0.3c0.4,0,0.8-0.1,1.3-0.4c0.5-0.3,0.9-0.6,1.4-1v-6c0-0.1-0.1-0.2-0.2-0.3
                        c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.2-0.1-0.4-0.1-0.6-0.2v-0.4c1-0.1,1.8-0.1,2.4-0.2s1-0.1,1.1-0.1
                        c0.1,0,0.1,0,0.2,0.1c0,0.1,0,0.2,0,0.3V150.8z"/>
                    <path class="style00" d="M1818.2,144.6c0-0.2,0-0.4-0.1-0.6s-0.1-0.3-0.2-0.4s-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3v-0.4
                        c0.3-0.1,0.5-0.2,0.8-0.2s0.5-0.2,0.7-0.2c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1
                        c0.1,0,0.2,0.1,0.2,0.3v1.4c0.3-0.2,0.6-0.4,0.9-0.6s0.6-0.4,0.9-0.5c0.3-0.2,0.6-0.3,1-0.4s0.7-0.1,1.1-0.1
                        c0.6,0,1.1,0.1,1.5,0.4c0.4,0.3,0.7,0.7,0.9,1.3c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.2,0.6-0.3,1-0.4
                        c0.3-0.1,0.7-0.2,1.1-0.2c0.5,0,0.9,0.1,1.2,0.3s0.6,0.4,0.8,0.7c0.2,0.3,0.4,0.6,0.5,1s0.1,0.8,0.1,1.2v5.8
                        c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.3v0.6c-0.4,0-0.7,0-1.1,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0
                        v-0.6h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-5c0-0.4,0-0.7-0.1-1c0-0.3-0.1-0.6-0.3-0.9s-0.3-0.5-0.6-0.6
                        c-0.3-0.2-0.6-0.2-1-0.2c-0.2,0-0.5,0-0.7,0.1c-0.3,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.2-0.5,0.3v6.6
                        c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0
                        v-0.6h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-5c0-0.4,0-0.7-0.1-1c0-0.3-0.1-0.6-0.3-0.9c-0.1-0.3-0.3-0.5-0.6-0.6
                        c-0.3-0.2-0.6-0.2-1-0.2c-0.2,0-0.5,0-0.7,0.1c-0.3,0.1-0.5,0.2-0.7,0.3s-0.5,0.2-0.7,0.3s-0.4,0.2-0.5,0.3v6.6
                        c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.3,0
                        v-0.6h0.5c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7V144.6z"/>
                    <path class="style00" d="M1836.9,144.8c0-0.2,0-0.4-0.1-0.5c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.3s-0.4-0.2-0.7-0.3v-0.4
                        c0.5-0.2,1-0.3,1.4-0.5s1-0.4,1.5-0.6c0.1,0,0.1,0.1,0.1,0.2v1.5c0.6-0.5,1.3-0.9,1.9-1.2c0.6-0.3,1.3-0.5,2-0.5
                        c0.5,0,0.9,0.1,1.2,0.2c0.3,0.2,0.6,0.4,0.8,0.7c0.2,0.3,0.3,0.6,0.4,1s0.1,0.8,0.1,1.2v5.8c0,0.4,0.1,0.6,0.3,0.7
                        c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0v-0.6h0.4
                        c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-5.8c0-0.6-0.2-1.1-0.6-1.4c-0.4-0.3-0.9-0.5-1.5-0.5c-0.2,0-0.4,0-0.6,0.1
                        s-0.5,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.3-0.6,0.4v6.6c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6
                        c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7
                        V144.8z"/>
                    <path class="style00" d="M1848.7,148.9c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.3,0.3,0.6,0.6,0.9s0.5,0.4,0.8,0.6c0.3,0.1,0.7,0.2,1.1,0.2
                        c0.3,0,0.6,0,0.8-0.1s0.4-0.2,0.6-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6c0-0.4-0.1-0.8-0.4-1.1
                        c-0.2-0.3-0.5-0.5-0.9-0.7s-0.8-0.4-1.2-0.6c-0.4-0.2-0.8-0.4-1.2-0.6c-0.4-0.3-0.7-0.6-0.9-1c-0.2-0.4-0.4-0.9-0.4-1.5
                        c0-0.3,0.1-0.6,0.2-1s0.3-0.6,0.6-0.9c0.3-0.3,0.6-0.5,1.1-0.7s1-0.3,1.6-0.3c0.4,0,0.8,0.1,1.2,0.2c0.5,0.1,0.9,0.3,1.3,0.5
                        l0.2,2.3h-0.5c-0.1-0.7-0.4-1.3-0.8-1.7s-1-0.6-1.7-0.6c-0.4,0-0.9,0.1-1.2,0.4c-0.4,0.3-0.5,0.6-0.5,1.1c0,0.4,0.1,0.8,0.4,1.1
                        c0.3,0.3,0.6,0.5,1,0.7c0.4,0.2,0.8,0.4,1.3,0.6s0.9,0.4,1.3,0.6c0.4,0.2,0.7,0.6,1,0.9c0.3,0.4,0.4,0.9,0.4,1.4
                        c0,0.4-0.1,0.7-0.2,1.1c-0.1,0.4-0.4,0.7-0.7,1s-0.7,0.5-1.2,0.7s-1.1,0.3-1.7,0.3c-0.4,0-0.9-0.1-1.4-0.2s-0.9-0.3-1.3-0.6
                        l-0.2-2.6L1848.7,148.9z"/>
                </g>
                <path id="Arrow_16_" class="style00" d="M1890.6,184.7c-4.9,15-11.2,29.6-18.9,43.4c0.5-2.2,1.2-4.4,2-6.5c1.5-4.1,3-8.2,4.3-12.4
                    c2.7-8.3,5-16.8,6.8-25.4c3.8-17.1,5.9-34.6,6.4-52.1c0.1-4.8,0.1-9.5,0-14.3c-0.1-2.5-0.3-5.1-0.1-7.6c0.2-2.4,0.7-4.8,1.4-7.1
                    c0.3-1.1-2.6-0.7-2.9,0.3c-0.6,2-1.1,4.1-1.3,6.2c-0.2,2-0.1,4.1,0,6.1c0.2,4.5,0.2,8.9,0.1,13.4c-0.1,8.8-0.7,17.5-1.7,26.2
                    c-1.9,17.4-5.5,34.7-10.7,51.4c-1.4,4.6-3,9.2-4.7,13.8c-1.1,3.1-2.2,6.2-2.9,9.4c-4.7-9.2-8.3-18.9-10.8-29
                    c-0.3-1.1-3.2-0.3-2.9,0.8c3,12,7.6,23.4,13.6,34.2c0.5,0.9,2.3,0,2.7-0.5c8.6-11.6,15.2-24.8,19.4-38.7c1.2-4,2.2-8,3-12
                    C1893.8,183.1,1890.9,183.8,1890.6,184.7z"/>
            </g>
        </g>


        <g class="lava-content">

            <g  id="lava-circle" v-bind:class="{'opac':over == 'lava'}">
                <path class="style00" d="M1205,402.2c-12.9,0-23.3-10.5-23.3-23.3s10.5-23.3,23.3-23.3c12.9,0,23.3,10.5,23.3,23.3
                    S1217.8,402.2,1205,402.2z M1205,360.5c-10.1,0-18.3,8.2-18.3,18.3s8.2,18.3,18.3,18.3s18.3-8.2,18.3-18.3S1215.1,360.5,1205,360.5
                    z"/>
            </g>

            <g id="lava-content" v-bind:class="{'opac':over != 'lava'}">
                <g id="Text_18_">
                    <path class="style00" d="M1266,496.5h-0.6c-0.1-0.7-0.3-1.2-0.6-1.7s-0.6-0.8-1-1.1c-0.4-0.3-0.9-0.5-1.4-0.7s-1.1-0.2-1.8-0.2
                        c-0.6,0-1.3,0.1-2.1,0.4s-1.4,0.6-2.1,1.2c-0.6,0.5-1.2,1.3-1.6,2.2s-0.6,2-0.6,3.4c0,1.1,0.2,2.2,0.6,3.1s0.9,1.8,1.5,2.5
                        c0.6,0.7,1.3,1.2,2.1,1.6c0.8,0.4,1.6,0.6,2.5,0.6c0.6,0,1.1-0.1,1.6-0.3s1-0.4,1.3-0.8s0.7-0.7,1-1.2c0.3-0.5,0.5-1,0.6-1.6
                        l0.5,0.1l-0.5,3.3c-0.2,0.2-0.6,0.3-1,0.4s-0.8,0.3-1.3,0.4s-0.9,0.2-1.4,0.3s-0.9,0.1-1.3,0.1c-1.3,0-2.5-0.2-3.6-0.6
                        c-1.1-0.4-2-1-2.7-1.7s-1.3-1.6-1.7-2.7s-0.6-2.2-0.6-3.4c0-1.2,0.2-2.2,0.7-3.2s1.1-1.8,1.9-2.5s1.7-1.2,2.7-1.6
                        c1-0.4,2.1-0.6,3.2-0.6c0.9,0,1.8,0.1,2.7,0.4s1.7,0.5,2.6,0.8L1266,496.5z"/>
                    <path class="style00" d="M1277.3,507.2c-0.3,0.3-0.7,0.6-1,0.8c-0.4,0.3-0.8,0.4-1.2,0.4c-0.5,0-0.8-0.2-1-0.5
                        c-0.2-0.4-0.3-0.7-0.4-1.1c-0.5,0.5-1.1,0.9-1.6,1.2s-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.2-1.8-0.6s-0.7-0.9-0.7-1.5
                        c0-0.4,0.1-0.8,0.3-1.1s0.5-0.6,0.9-0.8s1-0.5,1.8-0.8c0.8-0.3,1.7-0.6,3-1V501c0-1.3-0.7-1.9-2.2-1.9c-0.5,0-0.9,0.1-1.2,0.4
                        c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3-0.1,0.5-0.4,0.7s-0.6,0.2-0.8,0.2c-0.2,0-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
                        c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6s0.5-0.4,0.8-0.6c0.3-0.2,0.7-0.4,1.1-0.6c0.2-0.1,0.4-0.2,0.5-0.3
                        c0.2-0.1,0.3-0.2,0.5-0.2s0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.2,0.9,0.4s0.5,0.4,0.7,0.6
                        c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.5,0.1,0.8,0.2,1c0.1,0.2,0.3,0.3,0.6,0.3c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.2,0.4-0.3
                        L1277.3,507.2z M1273.7,503.1c-0.3,0.1-0.7,0.2-1.1,0.4c-0.5,0.1-0.9,0.3-1.3,0.5c-0.4,0.2-0.8,0.4-1.1,0.7
                        c-0.3,0.3-0.5,0.6-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1s0.7,0.5,1.1,0.5c0.5,0,1-0.1,1.3-0.4s0.7-0.6,1.1-0.9V503.1z"/>
                    <path class="style00" d="M1278.8,507.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-12.5c0-0.4,0-0.6-0.1-0.9s-0.1-0.4-0.2-0.5
                        s-0.2-0.2-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2v-0.4c0.4-0.1,0.9-0.3,1.3-0.5s0.8-0.3,1-0.5c0.1-0.1,0.2-0.1,0.3-0.2s0.1-0.1,0.2-0.1
                        c0.1,0,0.2,0.1,0.2,0.3v15.5c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0
                        s-0.8,0-1.2,0s-0.8,0-1.2,0V507.5z"/>
                    <path class="style00" d="M1285.1,507.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.1c0-0.2,0-0.4-0.1-0.6s-0.1-0.3-0.2-0.4
                        c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.5-0.3v-0.4c0.3-0.1,0.5-0.2,0.8-0.2s0.5-0.2,0.8-0.2s0.4-0.1,0.6-0.2
                        c0.1-0.1,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.3v8.6c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4
                        v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0s-0.8,0-1.2,0V507.5z M1288.6,493.3c0,0.3-0.1,0.6-0.3,0.8
                        s-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3s-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.3
                        S1288.6,493,1288.6,493.3z"/>
                    <path class="style00" d="M1297.7,498.2v1.2h-2.9v7.6c0,0.3,0.1,0.4,0.3,0.5s0.5,0.1,0.7,0.1h1v0.6c-0.5,0-1,0-1.4,0s-1,0-1.4,0
                        c-0.5,0-0.9,0-1.4,0s-0.9,0-1.4,0v-0.6h0.9c0.4,0,0.7-0.1,0.8-0.2s0.2-0.3,0.2-0.5v-7.4h-1.6V499c0.1-0.1,0.3-0.2,0.5-0.2
                        s0.4-0.2,0.5-0.3s0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.2-0.5c0-0.4,0-0.8,0.1-1.2c0.1-0.4,0.2-0.9,0.3-1.3c0.1-0.5,0.3-0.9,0.6-1.3
                        c0.2-0.4,0.5-0.8,0.9-1.2c0.6-0.6,1.2-1.1,1.7-1.3s0.8-0.3,1-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1
                        c0.1,0.1,0.2,0.2,0.3,0.3s0.1,0.3,0.1,0.5c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.2-0.3,0.2-0.6,0.2c-0.2,0-0.3,0-0.4,0s-0.2-0.1-0.3-0.1
                        s-0.2-0.1-0.3-0.1s-0.3,0-0.4,0c-0.3,0-0.6,0.1-0.8,0.3s-0.4,0.4-0.4,0.7c-0.1,0.3-0.2,0.5-0.2,0.8s0,0.6,0,0.8v3.2H1297.7z"/>
                    <path class="style00" d="M1299,503.2c0-0.7,0.1-1.4,0.4-2.1c0.3-0.7,0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.6-1.2s1.3-0.4,2.1-0.4
                        c0.8,0,1.5,0.1,2.2,0.4c0.7,0.2,1.2,0.6,1.7,1.1c0.5,0.5,0.9,1,1.1,1.7c0.3,0.7,0.4,1.4,0.4,2.2c0,0.7-0.1,1.4-0.4,2.1
                        s-0.6,1.2-1.1,1.7s-1,0.9-1.6,1.2s-1.3,0.4-2,0.4c-0.7,0-1.5-0.1-2.1-0.4c-0.7-0.2-1.2-0.6-1.7-1.1s-0.9-1-1.2-1.7
                        C1299.1,504.7,1299,504,1299,503.2z M1301.1,503c0,0.5,0.1,1,0.2,1.5c0.1,0.6,0.3,1.1,0.6,1.6s0.6,0.9,1,1.2s0.9,0.5,1.5,0.5
                        c0.6,0,1-0.1,1.4-0.4c0.4-0.3,0.7-0.7,1-1.1c0.3-0.4,0.4-0.9,0.6-1.5s0.2-1,0.2-1.5c0-0.5-0.1-1-0.2-1.5c-0.1-0.6-0.3-1.1-0.6-1.6
                        s-0.6-0.9-1-1.2s-0.9-0.5-1.5-0.5c-0.6,0-1,0.1-1.4,0.4s-0.7,0.7-1,1.1s-0.4,0.9-0.5,1.5S1301.1,502.5,1301.1,503z"/>
                    <path class="style00" d="M1311.1,507.5h0.6c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.3c0-0.2,0-0.3-0.1-0.4
                        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1v-0.3c0.8-0.3,1.5-0.6,1.9-0.9s0.7-0.4,0.8-0.4
                        c0.1,0,0.2,0.1,0.2,0.3v2h0c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.6-0.2,1-0.2
                        c0.3,0,0.6,0.1,0.9,0.3c0.3,0.2,0.4,0.5,0.4,0.9c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.4-0.1
                        c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
                        c-0.2,0.1-0.3,0.3-0.5,0.6c-0.1,0.2-0.2,0.5-0.2,0.9v5.3c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.3v0.6
                        c-0.6,0-1.2,0-1.7,0s-1,0-1.5,0c-0.5,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.3,0V507.5z"/>
                    <path class="style00" d="M1321.4,500.8c0-0.2,0-0.4-0.1-0.5c0-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.7-0.3
                        v-0.4c0.5-0.2,1-0.3,1.4-0.5c0.5-0.2,1-0.4,1.5-0.6c0.1,0,0.1,0.1,0.1,0.2v1.5c0.6-0.5,1.3-0.9,1.9-1.2c0.6-0.3,1.3-0.5,2-0.5
                        c0.5,0,0.9,0.1,1.2,0.2s0.6,0.4,0.8,0.7s0.3,0.6,0.4,1s0.1,0.8,0.1,1.2v5.8c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4
                        v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0s-0.8,0-1.2,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7V501
                        c0-0.6-0.2-1.1-0.5-1.4c-0.4-0.3-0.9-0.5-1.5-0.5c-0.2,0-0.4,0-0.6,0.1s-0.5,0.2-0.7,0.3s-0.5,0.2-0.7,0.3
                        c-0.2,0.1-0.4,0.3-0.6,0.4v6.6c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0
                        s-0.8,0-1.2,0s-0.8,0-1.2,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7V500.8z"/>
                    <path class="style00" d="M1332.5,507.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.1c0-0.2,0-0.4-0.1-0.6s-0.1-0.3-0.2-0.4
                        c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.2-0.5-0.3v-0.4c0.3-0.1,0.5-0.2,0.8-0.2s0.5-0.2,0.8-0.2c0.2-0.1,0.4-0.1,0.6-0.2
                        s0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.3v8.6c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6
                        c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0s-0.8,0-1.2,0V507.5z M1336,493.3c0,0.3-0.1,0.6-0.3,0.8
                        c-0.2,0.2-0.5,0.3-0.8,0.3s-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3
                        c0.3,0,0.6,0.1,0.8,0.3C1335.9,492.7,1336,493,1336,493.3z"/>
                    <path class="style00" d="M1348.5,507.2c-0.3,0.3-0.7,0.6-1,0.8c-0.4,0.3-0.8,0.4-1.2,0.4c-0.5,0-0.8-0.2-1-0.5s-0.3-0.7-0.4-1.1
                        c-0.5,0.5-1.1,0.9-1.6,1.2s-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.2-1.8-0.6s-0.7-0.9-0.7-1.5c0-0.4,0.1-0.8,0.3-1.1
                        c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.3,1-0.5,1.8-0.8s1.7-0.6,3-1V501c0-1.3-0.7-1.9-2.2-1.9c-0.5,0-0.9,0.1-1.2,0.4s-0.5,0.7-0.5,1.2
                        c0,0.3-0.1,0.5-0.4,0.7c-0.3,0.2-0.6,0.2-0.8,0.2c-0.2,0-0.3-0.1-0.3-0.2s-0.1-0.2-0.1-0.3c0-0.3,0.1-0.6,0.2-0.8
                        c0.1-0.2,0.3-0.4,0.5-0.6s0.5-0.4,0.8-0.6s0.7-0.4,1.1-0.6c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.3-0.2,0.5-0.2
                        c0.1-0.1,0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.2,0.9,0.4c0.3,0.2,0.5,0.4,0.7,0.6
                        c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.5,0.1,0.8,0.2,1c0.1,0.2,0.3,0.3,0.6,0.3c0.1,0,0.3,0,0.4-0.1s0.3-0.2,0.4-0.3L1348.5,507.2z
                        M1344.8,503.1c-0.3,0.1-0.7,0.2-1.1,0.4s-0.9,0.3-1.3,0.5s-0.8,0.4-1.1,0.7c-0.3,0.3-0.5,0.6-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1
                        s0.7,0.5,1.1,0.5c0.5,0,1-0.1,1.3-0.4c0.4-0.3,0.7-0.6,1.1-0.9V503.1z"/>
                    <path class="style00" d="M1251.4,533.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-12.5c0-0.4,0-0.6-0.1-0.9s-0.1-0.4-0.2-0.5
                        c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1-0.1-0.3-0.1-0.5-0.2v-0.4c0.4-0.1,0.9-0.3,1.3-0.5s0.8-0.3,1-0.5c0.1-0.1,0.2-0.1,0.3-0.2
                        s0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v15.5c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0
                        c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0s-0.8,0-1.2,0V533.5z"/>
                    <path class="style00" d="M1267.3,533.2c-0.3,0.3-0.7,0.6-1,0.8c-0.4,0.3-0.8,0.4-1.2,0.4c-0.5,0-0.8-0.2-1-0.5s-0.3-0.7-0.4-1.1
                        c-0.5,0.5-1.1,0.9-1.6,1.2s-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.2-1.8-0.6s-0.7-0.9-0.7-1.5c0-0.4,0.1-0.8,0.3-1.1
                        c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.3,1-0.5,1.8-0.8s1.7-0.6,3-1V527c0-1.3-0.7-1.9-2.2-1.9c-0.5,0-0.9,0.1-1.2,0.4s-0.5,0.7-0.5,1.2
                        c0,0.3-0.1,0.5-0.4,0.7c-0.3,0.2-0.6,0.2-0.8,0.2c-0.2,0-0.3-0.1-0.3-0.2s-0.1-0.2-0.1-0.3c0-0.3,0.1-0.6,0.2-0.8
                        c0.1-0.2,0.3-0.4,0.5-0.6s0.5-0.4,0.8-0.6s0.7-0.4,1.1-0.6c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.3-0.2,0.5-0.2
                        c0.1-0.1,0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.2,0.9,0.4c0.3,0.2,0.5,0.4,0.7,0.6
                        c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.5,0.1,0.8,0.2,1c0.1,0.2,0.3,0.3,0.6,0.3c0.1,0,0.3,0,0.4-0.1s0.3-0.2,0.4-0.3L1267.3,533.2z
                        M1263.7,529.1c-0.3,0.1-0.7,0.2-1.1,0.4s-0.9,0.3-1.3,0.5s-0.8,0.4-1.1,0.7c-0.3,0.3-0.5,0.6-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1
                        s0.7,0.5,1.1,0.5c0.5,0,1-0.1,1.3-0.4c0.4-0.3,0.7-0.6,1.1-0.9V529.1z"/>
                    <path class="style00" d="M1270.3,526.5c-0.2-0.4-0.3-0.7-0.4-1c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0-0.5,0
                        v-0.6c0.4,0,0.7,0,1.1,0c0.4,0,0.7,0,1.1,0c0.4,0,0.8,0,1.2,0c0.4,0,0.8,0,1.2,0v0.6c-0.5,0-0.9,0-1,0.1s-0.3,0.2-0.3,0.5
                        c0,0.2,0.1,0.4,0.2,0.8c0.1,0.3,0.3,0.8,0.5,1.3l1.6,4.3l1.9-4.6c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.1-0.6,0.1-0.8
                        c0-0.2-0.1-0.4-0.2-0.4c-0.2-0.1-0.5-0.1-1-0.2v-0.6c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c0.3,0,0.7,0,1,0s0.7,0,1,0v0.6
                        c-0.5,0-0.9,0.1-1.1,0.4s-0.5,0.7-0.7,1.2l-3.2,8.1h-0.6L1270.3,526.5z"/>
                    <path class="style00" d="M1289.8,533.2c-0.3,0.3-0.7,0.6-1,0.8c-0.4,0.3-0.8,0.4-1.2,0.4c-0.5,0-0.8-0.2-1-0.5
                        c-0.2-0.4-0.3-0.7-0.4-1.1c-0.5,0.5-1.1,0.9-1.6,1.2s-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.2-1.8-0.6s-0.7-0.9-0.7-1.5
                        c0-0.4,0.1-0.8,0.3-1.1s0.5-0.6,0.9-0.8s1-0.5,1.8-0.8c0.8-0.3,1.7-0.6,3-1V527c0-1.3-0.7-1.9-2.2-1.9c-0.5,0-0.9,0.1-1.2,0.4
                        c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3-0.1,0.5-0.4,0.7s-0.6,0.2-0.8,0.2c-0.2,0-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
                        c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6s0.5-0.4,0.8-0.6c0.3-0.2,0.7-0.4,1.1-0.6c0.2-0.1,0.4-0.2,0.5-0.3
                        c0.2-0.1,0.3-0.2,0.5-0.2s0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.2,0.9,0.4s0.5,0.4,0.7,0.6
                        c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.5,0.1,0.8,0.2,1c0.1,0.2,0.3,0.3,0.6,0.3c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.2,0.4-0.3
                        L1289.8,533.2z M1286.2,529.1c-0.3,0.1-0.7,0.2-1.1,0.4c-0.5,0.1-0.9,0.3-1.3,0.5c-0.4,0.2-0.8,0.4-1.1,0.7
                        c-0.3,0.3-0.5,0.6-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1s0.7,0.5,1.1,0.5c0.5,0,1-0.1,1.3-0.4s0.7-0.6,1.1-0.9V529.1z"/>
                    <path class="style00" d="M1297.4,533.5h0.6c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.3c0-0.2,0-0.3-0.1-0.4
                        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1v-0.3c0.8-0.3,1.5-0.6,1.9-0.9s0.7-0.4,0.8-0.4
                        c0.1,0,0.2,0.1,0.2,0.3v2h0c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.6-0.2,1-0.2
                        c0.3,0,0.6,0.1,0.9,0.3c0.3,0.2,0.4,0.5,0.4,0.9c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.4-0.1
                        c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
                        c-0.2,0.1-0.3,0.3-0.5,0.6c-0.1,0.2-0.2,0.5-0.2,0.9v5.3c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.3v0.6
                        c-0.6,0-1.2,0-1.7,0s-1,0-1.5,0c-0.5,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.3,0V533.5z"/>
                    <path class="style00" d="M1306.5,529.2c0-0.7,0.1-1.4,0.4-2.1c0.3-0.7,0.6-1.2,1.1-1.7c0.5-0.5,1-0.9,1.6-1.2s1.3-0.4,2.1-0.4
                        c0.8,0,1.5,0.1,2.2,0.4c0.7,0.2,1.2,0.6,1.7,1.1c0.5,0.5,0.9,1,1.1,1.7c0.3,0.7,0.4,1.4,0.4,2.2c0,0.7-0.1,1.4-0.4,2.1
                        s-0.6,1.2-1.1,1.7s-1,0.9-1.6,1.2s-1.3,0.4-2,0.4c-0.7,0-1.5-0.1-2.1-0.4c-0.7-0.2-1.2-0.6-1.7-1.1s-0.9-1-1.2-1.7
                        C1306.6,530.7,1306.5,530,1306.5,529.2z M1308.6,529c0,0.5,0.1,1,0.2,1.5c0.1,0.6,0.3,1.1,0.6,1.6s0.6,0.9,1,1.2s0.9,0.5,1.5,0.5
                        c0.6,0,1-0.1,1.4-0.4c0.4-0.3,0.7-0.7,1-1.1c0.3-0.4,0.4-0.9,0.6-1.5s0.2-1,0.2-1.5c0-0.5-0.1-1-0.2-1.5c-0.1-0.6-0.3-1.1-0.6-1.6
                        s-0.6-0.9-1-1.2s-0.9-0.5-1.5-0.5c-0.6,0-1,0.1-1.4,0.4s-0.7,0.7-1,1.1s-0.4,0.9-0.5,1.5S1308.6,528.5,1308.6,529z"/>
                    <path class="style00" d="M1327.2,525.4c0,0.3-0.1,0.5-0.2,0.7c-0.2,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3
                        c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.2-0.7-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.3,0.4c-0.4,0.2-0.7,0.5-0.9,0.9
                        s-0.4,0.8-0.6,1.3c-0.1,0.5-0.2,0.9-0.2,1.3c0,0.6,0.1,1.1,0.3,1.7c0.2,0.5,0.4,1,0.8,1.4s0.7,0.7,1.2,1c0.5,0.2,1,0.4,1.7,0.4
                        c0.6,0,1.1-0.1,1.5-0.3c0.4-0.2,0.8-0.4,1.3-0.8l0.4,0.4c-0.6,0.6-1.2,1.2-1.9,1.7c-0.7,0.5-1.5,0.7-2.4,0.7
                        c-0.6,0-1.1-0.1-1.7-0.4s-1-0.6-1.4-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.6-0.4-1.1-0.4-1.7c0-0.8,0.1-1.6,0.4-2.3
                        c0.3-0.7,0.7-1.4,1.2-1.9s1.1-1,1.8-1.3c0.7-0.3,1.4-0.5,2.2-0.5c0.3,0,0.6,0,1,0.1c0.4,0.1,0.7,0.1,1,0.3
                        c0.3,0.1,0.6,0.3,0.8,0.5C1327.1,524.9,1327.2,525.1,1327.2,525.4z"/>
                    <path class="style00" d="M1339.3,524.8c-0.6,0-1.1,0.1-1.5,0.3c-0.4,0.2-0.8,0.5-1.2,0.9l-2.5,2.2l3.8,4.2c0.2,0.3,0.4,0.4,0.6,0.6
                        s0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.5,0.1s0.4,0,0.6,0.1v0.5c-0.3,0-0.6,0-0.8,0s-0.6,0-0.9,0c-0.3,0-0.5,0-0.8,0s-0.5,0-0.8,0
                        c-0.2-0.3-0.5-0.6-0.7-0.9l-3.6-4h-0.4v3.6c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4v0.6c-0.4,0-0.8,0-1.2,0
                        c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0s-0.8,0-1.2,0v-0.6h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-12.5c0-0.4,0-0.6-0.1-0.9
                        s-0.1-0.4-0.2-0.5s-0.2-0.2-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2v-0.4c0.4-0.1,0.9-0.3,1.3-0.5s0.8-0.3,1-0.5
                        c0.1-0.1,0.2-0.1,0.3-0.2s0.1-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.3v11.4l0.7-0.1l2.4-2.1c0.2-0.2,0.4-0.4,0.6-0.6s0.3-0.4,0.3-0.6
                        c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1s-0.2,0-0.4,0c-0.1,0-0.2,0-0.3,0v-0.6c0.4,0,0.8,0,1.2,0c0.4,0,0.8,0,1.2,0
                        c0.3,0,0.7,0,1,0c0.4,0,0.7,0,1,0V524.8z"/>
                </g>
                <path id="Arrow_18_" class="style00" d="M1220.8,414.9c-0.2-0.7-2.6,0.3-2.9,0.7c-8.5,10.1-16,20.9-22.6,32.2c-0.2,0.4,0.9,0.3,1,0.3
                    c0.5-0.1,1.4-0.2,1.8-0.7c5.7-6.2,10.8-12.9,15.2-20c-3.2,24.4-5.3,48.8-5.8,73.4c-0.2,7.6-0.2,15.1-0.1,22.7c0,0.4,2.9,0,2.9-0.8
                    c-0.4-26.5,0.9-53,3.8-79.3c0.8-7.5,1.8-14.9,2.9-22.4c0-0.2-0.4-0.2-0.9-0.1c0.9-1.1,1.7-2.2,2.6-3.3c2.9,9.5,5.7,19,8.6,28.5
                    c0.2,0.6,3.1-0.2,2.9-0.8C1227,435.2,1223.9,425,1220.8,414.9z"/>
            </g>
            
        
        </g>


        
        <g class="air-content">
            <g id="air-circle" v-bind:class="{'opac':over == 'air'}">
                <path class="style00" d="M1572.9,391.3c-12.9,0-23.3-10.5-23.3-23.3s10.5-23.3,23.3-23.3s23.3,10.5,23.3,23.3
                    S1585.8,391.3,1572.9,391.3z M1572.9,349.7c-10.1,0-18.3,8.2-18.3,18.3s8.2,18.3,18.3,18.3s18.3-8.2,18.3-18.3
                    S1583,349.7,1572.9,349.7z"/>
            </g>

            <g id="air-content" v-bind:class="{'opac':over != 'air'}">
                <g id="Text_17_">
                    <path class="style00" d="M1604.9,507.7c1.1-1,2.1-1.9,3-2.8c0.9-0.9,1.6-1.8,2.3-2.7s1.1-1.7,1.4-2.5c0.3-0.8,0.5-1.6,0.5-2.3
                        c0-0.4-0.1-0.9-0.2-1.3c-0.1-0.4-0.3-0.8-0.5-1.1c-0.2-0.3-0.5-0.6-0.9-0.8s-0.8-0.3-1.3-0.3c-0.4,0-0.7,0.1-1,0.2
                        c-0.3,0.1-0.6,0.3-0.8,0.5c-0.3,0.2-0.5,0.4-0.7,0.7c-0.2,0.3-0.4,0.5-0.7,0.8l-0.4-0.4c0.3-0.4,0.5-0.7,0.8-1.1s0.6-0.7,1-1
                        s0.8-0.5,1.3-0.7s1-0.3,1.6-0.3s1.1,0.1,1.6,0.3c0.5,0.2,0.9,0.5,1.2,0.9c0.3,0.4,0.6,0.8,0.8,1.3c0.2,0.5,0.3,1,0.3,1.6
                        c0,1-0.2,1.9-0.7,2.8c-0.5,0.9-1.1,1.8-1.8,2.6c-0.7,0.8-1.4,1.6-2.2,2.4s-1.5,1.4-2.1,2.1h7.3l0.5-1.2h0.6l-0.7,3h-10.2V507.7z"
                        />
                    <path class="style00" d="M1617.4,502.3l7.7-10h1v9.4h2.2v1.5h-2.2v4.9h-1.9v-4.9h-6.7V502.3z M1624.1,495L1624.1,495l-5.1,6.7h5.1V495
                        z"/>
                    <path class="style00" d="M1629.1,508.5l5.3-16.4h1.5l-5.3,16.4H1629.1z"/>
                    <path class="style00" d="M1637.5,492.6h10.1l0.2,0.2l-8,15.7l-1-0.3l7.2-13.7h-7.9l-0.6,1.3l-0.6-0.1L1637.5,492.6z"/>
                    <path class="style00" d="M1664.8,507.2c-0.3,0.3-0.7,0.6-1,0.8c-0.4,0.3-0.8,0.4-1.2,0.4c-0.5,0-0.8-0.2-1-0.5
                        c-0.2-0.4-0.3-0.7-0.4-1.1c-0.5,0.5-1.1,0.9-1.6,1.2s-1.2,0.5-1.9,0.5c-0.7,0-1.3-0.2-1.8-0.6s-0.7-0.9-0.7-1.5
                        c0-0.4,0.1-0.8,0.3-1.1s0.5-0.6,0.9-0.8s1-0.5,1.8-0.8c0.8-0.3,1.7-0.6,3-1V501c0-1.3-0.7-1.9-2.2-1.9c-0.5,0-0.9,0.1-1.2,0.4
                        c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3-0.1,0.5-0.4,0.7s-0.6,0.2-0.8,0.2c-0.2,0-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3
                        c0-0.3,0.1-0.6,0.2-0.8s0.3-0.4,0.5-0.6s0.5-0.4,0.8-0.6c0.3-0.2,0.7-0.4,1.1-0.6c0.2-0.1,0.4-0.2,0.5-0.3
                        c0.2-0.1,0.3-0.2,0.5-0.2s0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5,0,0.9,0.1c0.3,0.1,0.6,0.2,0.9,0.4s0.5,0.4,0.7,0.6
                        c0.2,0.2,0.3,0.5,0.3,0.8v6.2c0,0.5,0.1,0.8,0.2,1c0.1,0.2,0.3,0.3,0.6,0.3c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.2,0.4-0.3
                        L1664.8,507.2z M1661.2,503.1c-0.3,0.1-0.7,0.2-1.1,0.4c-0.5,0.1-0.9,0.3-1.3,0.5c-0.4,0.2-0.8,0.4-1.1,0.7
                        c-0.3,0.3-0.5,0.6-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1s0.7,0.5,1.1,0.5c0.5,0,1-0.1,1.3-0.4s0.7-0.6,1.1-0.9V503.1z"/>
                    <path class="style00" d="M1666.3,507.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.1c0-0.2,0-0.4-0.1-0.6s-0.1-0.3-0.2-0.4
                        c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.5-0.3v-0.4c0.3-0.1,0.5-0.2,0.8-0.2s0.5-0.2,0.8-0.2s0.4-0.1,0.6-0.2
                        c0.1-0.1,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.3v8.6c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4
                        v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0s-0.8,0-1.2,0V507.5z M1669.8,493.3c0,0.3-0.1,0.6-0.3,0.8
                        s-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3s-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.3
                        S1669.8,493,1669.8,493.3z"/>
                    <path class="style00" d="M1672.4,507.5h0.6c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.3c0-0.2,0-0.3-0.1-0.4
                        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1v-0.3c0.8-0.3,1.5-0.6,1.9-0.9s0.7-0.4,0.8-0.4
                        c0.1,0,0.2,0.1,0.2,0.3v2h0c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.6-0.2,1-0.2
                        c0.3,0,0.6,0.1,0.9,0.3c0.3,0.2,0.4,0.5,0.4,0.9c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.4-0.1
                        c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
                        c-0.2,0.1-0.3,0.3-0.5,0.6c-0.1,0.2-0.2,0.5-0.2,0.9v5.3c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.3v0.6
                        c-0.6,0-1.2,0-1.7,0s-1,0-1.5,0c-0.5,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.3,0V507.5z"/>
                    <path class="style00" d="M1608.4,538.1c0,0.3,0.1,0.4,0.3,0.5s0.5,0.1,0.7,0.1h1v0.6c-0.5,0-1,0-1.4,0c-0.5,0-1,0-1.4,0
                        c-0.5,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.4,0v-0.6h0.9c0.4,0,0.7-0.1,0.8-0.2s0.2-0.3,0.2-0.5v-10.6c0-0.4,0-0.7-0.1-1
                        c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2V525c0.4-0.2,0.9-0.4,1.3-0.6
                        c0.4-0.2,0.9-0.4,1.3-0.6c0,0,0.1,0,0.1-0.1s0.1,0,0.1,0c0,0,0.1,0,0.2,0s0.1,0.1,0.1,0.2v1.6c0.2-0.2,0.5-0.4,0.7-0.6
                        s0.5-0.4,0.8-0.5s0.6-0.3,0.9-0.4c0.3-0.1,0.6-0.1,1-0.1c0.6,0,1.2,0.1,1.7,0.4c0.5,0.3,0.9,0.6,1.3,1.1s0.6,1,0.8,1.5
                        c0.2,0.6,0.3,1.2,0.3,1.7c0,0.8-0.1,1.5-0.4,2.2c-0.3,0.7-0.7,1.3-1.2,1.9s-1.1,1-1.8,1.3s-1.4,0.5-2.2,0.5c-0.3,0-0.6,0-1-0.1
                        c-0.4-0.1-0.7-0.2-1-0.3V538.1z M1614.1,529.6c0-0.5-0.1-1.1-0.2-1.6c-0.2-0.5-0.4-1-0.7-1.3c-0.3-0.4-0.7-0.7-1.2-0.9
                        s-1-0.4-1.6-0.4c-0.4,0-0.7,0.1-1.1,0.2c-0.4,0.2-0.7,0.3-0.9,0.5v6.1c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.3,0.5,0.5
                        s0.4,0.3,0.7,0.4s0.6,0.2,0.9,0.2c0.5,0,1-0.1,1.4-0.4c0.4-0.3,0.8-0.6,1-1s0.5-0.9,0.6-1.3S1614.1,530,1614.1,529.6z"/>
                    <path class="style00" d="M1627.2,532.9c0,0.2,0.1,0.3,0.2,0.4s0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0s0.3,0,0.5,0v0.5
                        c-0.2,0-0.5,0.1-0.9,0.1c-0.3,0.1-0.7,0.1-1,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.3,0.1-0.4,0.1-0.6,0.1l-0.1-0.1v-1.8
                        c-0.7,0.6-1.3,1-1.9,1.4s-1.3,0.5-2.1,0.5c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.4-0.5-0.6-0.8
                        s-0.2-0.7-0.2-1.2V526c0-0.2,0-0.3,0-0.4s-0.1-0.3-0.2-0.4s-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.7-0.2v-0.4c0.2,0,0.4,0,0.8,0
                        s0.6,0,0.9-0.1s0.6,0,0.8,0s0.4,0,0.5,0c0.1,0,0.2,0,0.2,0.1s0,0.3,0,0.5v5.8c0,0.2,0,0.4,0,0.7s0.1,0.6,0.2,0.9
                        c0.1,0.3,0.3,0.5,0.6,0.7c0.3,0.2,0.6,0.3,1.1,0.3c0.4,0,0.8-0.1,1.3-0.4s0.9-0.6,1.4-1v-6c0-0.1-0.1-0.2-0.2-0.3
                        c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2-0.1-0.4-0.1-0.6-0.2s-0.4-0.1-0.6-0.2v-0.4c1-0.1,1.8-0.1,2.4-0.2s1-0.1,1.1-0.1
                        c0.1,0,0.1,0,0.2,0.1s0,0.2,0,0.3V532.9z"/>
                    <path class="style00" d="M1629.9,533.5h0.6c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.3c0-0.2,0-0.3-0.1-0.4
                        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1v-0.3c0.8-0.3,1.5-0.6,1.9-0.9s0.7-0.4,0.8-0.4
                        c0.1,0,0.2,0.1,0.2,0.3v2h0c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.6-0.8c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.2,0.6-0.2,1-0.2
                        c0.3,0,0.6,0.1,0.9,0.3c0.3,0.2,0.4,0.5,0.4,0.9c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.4-0.1
                        c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.4,0.1c-0.2,0.1-0.4,0.2-0.6,0.3
                        c-0.2,0.1-0.3,0.3-0.5,0.6c-0.1,0.2-0.2,0.5-0.2,0.9v5.3c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.3v0.6
                        c-0.6,0-1.2,0-1.7,0s-1,0-1.5,0c-0.5,0-0.9,0-1.4,0c-0.5,0-0.9,0-1.3,0V533.5z"/>
                    <path class="style00" d="M1638.8,533.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.1c0-0.2,0-0.4-0.1-0.6s-0.1-0.3-0.2-0.4
                        c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.5-0.3v-0.4c0.3-0.1,0.5-0.2,0.8-0.2s0.5-0.2,0.8-0.2s0.4-0.1,0.6-0.2
                        c0.1-0.1,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.3v8.6c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4
                        v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0s-0.8,0-1.2,0V533.5z M1642.3,519.3c0,0.3-0.1,0.6-0.3,0.8
                        s-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3s-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.3
                        S1642.3,519,1642.3,519.3z"/>
                    <path class="style00" d="M1651.5,524.2v1.2h-2.9v7.6c0,0.3,0.1,0.4,0.3,0.5s0.5,0.1,0.7,0.1h1v0.6c-0.5,0-1,0-1.4,0s-1,0-1.4,0
                        c-0.5,0-0.9,0-1.4,0s-0.9,0-1.4,0v-0.6h0.9c0.4,0,0.7-0.1,0.8-0.2s0.2-0.3,0.2-0.5v-7.4h-1.6V525c0.1-0.1,0.3-0.2,0.5-0.2
                        s0.4-0.2,0.5-0.3s0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.2-0.5c0-0.4,0-0.8,0.1-1.2c0.1-0.4,0.2-0.9,0.3-1.3c0.1-0.5,0.3-0.9,0.6-1.3
                        c0.2-0.4,0.5-0.8,0.9-1.2c0.6-0.6,1.2-1.1,1.7-1.3s0.8-0.3,1-0.3c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.1
                        c0.1,0.1,0.2,0.2,0.3,0.3s0.1,0.3,0.1,0.5c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.2-0.3,0.2-0.6,0.2c-0.2,0-0.3,0-0.4,0s-0.2-0.1-0.3-0.1
                        s-0.2-0.1-0.3-0.1s-0.3,0-0.4,0c-0.3,0-0.6,0.1-0.8,0.3s-0.4,0.4-0.4,0.7c-0.1,0.3-0.2,0.5-0.2,0.8s0,0.6,0,0.8v3.2H1651.5z"/>
                    <path class="style00" d="M1652.6,533.5h0.4c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.1c0-0.2,0-0.4-0.1-0.6s-0.1-0.3-0.2-0.4
                        c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.3-0.2-0.5-0.3v-0.4c0.3-0.1,0.5-0.2,0.8-0.2s0.5-0.2,0.8-0.2s0.4-0.1,0.6-0.2
                        c0.1-0.1,0.2-0.1,0.3-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2,0.1,0.2,0.3v8.6c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h0.4
                        v0.6c-0.4,0-0.8,0-1.2,0c-0.4,0-0.8,0-1.2,0s-0.8,0-1.2,0s-0.8,0-1.2,0V533.5z M1656.1,519.3c0,0.3-0.1,0.6-0.3,0.8
                        s-0.5,0.3-0.8,0.3c-0.3,0-0.6-0.1-0.8-0.3s-0.3-0.5-0.3-0.8c0-0.3,0.1-0.5,0.3-0.8c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.3
                        S1656.1,519,1656.1,519.3z"/>
                    <path class="style00" d="M1660.6,527.9c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.9c0,0.5,0.1,1,0.3,1.5s0.4,0.9,0.7,1.2s0.6,0.6,1,0.8
                        c0.4,0.2,0.8,0.3,1.3,0.3c0.6,0,1.2-0.1,1.7-0.4s0.9-0.6,1.4-1.1l0.3,0.3c-0.3,0.3-0.5,0.7-0.8,1s-0.6,0.6-1,0.8
                        c-0.3,0.2-0.7,0.4-1.1,0.5c-0.4,0.1-0.8,0.2-1.3,0.2c-0.9,0-1.6-0.2-2.2-0.6c-0.6-0.4-1-0.8-1.3-1.3c-0.3-0.5-0.5-1.1-0.6-1.6
                        c-0.1-0.6-0.2-1-0.2-1.4c0-1.1,0.2-2,0.5-2.7c0.3-0.7,0.7-1.3,1.2-1.8c0.5-0.4,1-0.8,1.5-0.9c0.5-0.2,1-0.3,1.4-0.3
                        c0.4,0,0.9,0.1,1.3,0.2c0.4,0.1,0.9,0.4,1.2,0.7s0.7,0.7,0.9,1.1c0.2,0.4,0.3,1,0.3,1.6c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
                        l-0.2,0.1H1660.6z M1664.6,527.2c0.3,0,0.5-0.1,0.5-0.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.6-0.2-1.1-0.5-1.5s-0.8-0.6-1.4-0.6
                        c-0.7,0-1.3,0.2-1.7,0.7s-0.7,1.1-0.9,1.9H1664.6z"/>
                    <path class="style00" d="M1668.6,533.5h0.6c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.3-0.3,0.3-0.7v-6.3c0-0.2,0-0.3-0.1-0.4
                        c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.3-0.1-0.4-0.2s-0.2-0.1-0.3-0.1v-0.3c0.8-0.3,1.5-0.6,1.9-0.9s0.7-0.4,0.8-0.4
                        c0.1,0,0.2,0.1,0.2,0.3v2h0c0.1-0.2,0.3-0.5,0.5-0.7c0.2-0.3,0.4-0.5,0.6-0.8s0.5-0.4,0.8-0.6s0.6-0.2,1-0.2
                        c0.3,0,0.6,0.1,0.9,0.3c0.3,0.2,0.4,0.5,0.4,0.9c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.4-0.1
                        c-0.1-0.1-0.2-0.1-0.3-0.2s-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.4,0.1s-0.4,0.2-0.5,0.3
                        c-0.2,0.1-0.3,0.3-0.5,0.6c-0.1,0.2-0.2,0.5-0.2,0.9v5.3c0,0.4,0.1,0.6,0.3,0.7c0.2,0.1,0.5,0.1,0.8,0.1h1.3v0.6
                        c-0.6,0-1.2,0-1.7,0s-1,0-1.5,0c-0.5,0-0.9,0-1.4,0s-0.9,0-1.3,0V533.5z"/>
                    <path class="style00" d="M1678.1,531c0.1,0.4,0.2,0.7,0.3,1.1c0.1,0.3,0.3,0.6,0.6,0.9c0.2,0.2,0.5,0.4,0.8,0.6
                        c0.3,0.2,0.7,0.2,1.1,0.2c0.3,0,0.6,0,0.8-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0.1-0.6
                        c0-0.4-0.1-0.8-0.4-1.1s-0.5-0.5-0.9-0.7c-0.4-0.2-0.8-0.4-1.2-0.6s-0.8-0.4-1.2-0.6c-0.4-0.3-0.7-0.6-0.9-1s-0.4-0.9-0.4-1.5
                        c0-0.3,0.1-0.6,0.2-1c0.1-0.3,0.3-0.6,0.6-0.9s0.6-0.5,1.1-0.7c0.4-0.2,1-0.3,1.6-0.3c0.4,0,0.8,0.1,1.2,0.2
                        c0.5,0.1,0.9,0.3,1.3,0.5l0.2,2.3h-0.5c-0.1-0.7-0.4-1.3-0.8-1.7c-0.4-0.4-1-0.6-1.7-0.6c-0.4,0-0.9,0.1-1.2,0.4s-0.5,0.6-0.5,1.1
                        c0,0.4,0.1,0.8,0.4,1.1s0.6,0.5,1,0.7s0.8,0.4,1.3,0.6s0.9,0.4,1.3,0.6s0.7,0.6,1,0.9c0.3,0.4,0.4,0.9,0.4,1.4
                        c0,0.4-0.1,0.7-0.2,1.1c-0.2,0.4-0.4,0.7-0.7,1s-0.7,0.5-1.2,0.7c-0.5,0.2-1.1,0.3-1.7,0.3c-0.4,0-0.9-0.1-1.4-0.2
                        c-0.5-0.1-0.9-0.3-1.3-0.6l-0.2-2.6L1678.1,531z"/>
                </g>
                <path id="Arrow_17_" class="style00" d="M1597.2,427.3c-2.9-4.9-5.9-9.9-8.8-14.8c-1.1-2.4-2.1-4.8-2.9-7.3c-0.3-1-2.1-0.6-2.6,0
                    c-6.8,8.8-13.6,17.6-20.4,26.4c-1.1,1.4,1.6,1.7,2.4,0.7c5.9-7.6,11.8-15.2,17.6-22.8c-2.3,7.6-2.4,15.5-2.7,23.4
                    c-0.4,8.8-0.9,17.5-1,26.3c-0.3,17.5,0.2,35.1,1.1,52.6c0.5,9.8,1.2,19.7,1.9,29.5c0.1,1.2,3,0.5,2.9-0.8
                    c-1.3-17.4-2.3-34.9-2.8-52.4c-0.5-17.5-0.2-35,0.7-52.5c0.4-7.9,0.3-16,2.1-23.8c0.3,0.5,0.6,1.1,0.9,1.6
                    c2.4,5.3,5.4,10.4,8.9,15.1C1595.2,429.5,1597.9,428.6,1597.2,427.3z"/>
            </g>
        </g>
        
        
        
        
        
        <rect id="tesla_batteries_hover" x="62.7" y="301.9" class="style01" width="138.6" height="101.6" @mouseover="over = 'tesla'" @mouseout="over = null" />
        <rect id="recycled_aluminium_roof_hover" x="430.3" y="233" class="style01" width="138.6" height="101.6" @mouseover="over = 'aluminium'" @mouseout="over = null"/>
        <rect id="recycled_concrete_hover" x="608.3" y="342.1" class="style01" width="138.6" height="101.6" @mouseover="over = 'concrete'" @mouseout="over = null"/>
        <rect id="california_lava_rock_hover" x="1135.7" y="328.1" class="style01" width="138.6" height="101.6" @mouseover="over = 'lava'" @mouseout="over = null"/>
        <rect id="solar_panels_hover" x="1354.4" y="207.6" class="style01" width="138.6" height="101.6" @mouseover="over = 'solar'" @mouseout="over = null"/>
        <rect id="air_purifiers_hover" x="1503.6" y="317.2" class="style01" width="138.6" height="101.6" @mouseover="over = 'air'" @mouseout="over = null"/>
        <rect id="fsc_accoya_hover" x="1789.9" y="224.8" class="style01" width="138.6" height="101.6" @mouseover="over = 'fsc'" @mouseout="over = null"/>

    </svg>
  </div>
</template>

<style type="text/css">
	/* .style00{fill:#D8D800;}
	.style01{fill:#D82900;}
	.style02{fill:#00D8BE;}
	.st3{fill:#C800D8;}
	.st4{fill:#1A00D8;}
	.st5{fill:#30DB00;}
	.st6{fill:none;stroke:#006FDF;stroke-width:4;stroke-miterlimit:10;}
	.st7{fill:#006FDF;} */

/* .style00 {
  fill: #006fdf;
}
.style01 {
  fill: #dd0074;
}
.style02 {
  fill: #30db00;
}
*/
/* .st8, .st9, .style010, .style011, .style012, .style013 {
  fill: transparent;
} */
/* .st6 {
  fill: transparent;
  stroke: #0060df;
  stroke-width: 4;
  stroke-miterlimit: 10;
} */
/* .st7 {
  fill: #006fdf;
} */

.style01 {
  fill: transparent;
}
#board_arrow,
#board_punto,
#solar_punto,
#solar_arrow,
#fsc_punto,
#fsc_arrow,
#raised_punto,
#raised_arrow,
#_x39_9_punto,
#_x39_9_arrow,
#tesla_punto,
#tesla_arrow,
#tesla-circle,
#tesla-content,
#aluminium-circle,
#aluminium-content,
#concrete-circle,
#concrete-content,
#solar-circle,
#solar-content,
#fsc-circle,
#fsc-content,
#air-circle,
#air-content,
#lava-circle,
#lava-content{
  transition: 1s ease opacity;

  g{
    transition: 1s ease opacity;
  }
}
.opac {
  opacity: 0;
}


#Hover_19_{
    fill: transparent;
}

/* 
.style00{fill:#D8D800;}
			.style01{fill:#D82900;}
			.style02{fill:#00D8BE;}
			.st3{fill:#C800D8;}
			.st4{fill:#1A00D8;}
			.st5{fill:#30DB00;}
			.st6{fill:none;stroke:#FFFFFF;stroke-width:4;stroke-miterlimit:10;}
			.st7{fill:#006FDF;} */
</style>

<script>
export default {
  data() {
    return {
      over: 'solar',
      accoya: false,
      raised: false,
      recycled: false,
    };
  },
};
</script>

<style scoped lang="scss">
            .style00{fill:#3067A9;}
            // .style01{fill:none;}
            .style02{fill:none;stroke:#3067A9;stroke-width:5;stroke-miterlimit:10;}
        </style>